<template>
    <div class="productionDelivery productionShipment" v-loading="initLoading">
        <header class="title">
            <div class="tabs el_left">
                <!-- <el-tabs
                    v-model="activeName"
                    @tab-click="handleClick"
                >
                    <template v-for="(item, index) in siteData">
                        <el-tab-pane
                            :name="item.mixstation_code"
                            :key="index"
                        >
                            <span slot="label">{{ item.mixstation_name }}</span>
                        </el-tab-pane>
                    </template>
                </el-tabs> -->
                <div class="car-state-title">
                    <div
                        v-for="(item, index) in siteData"
                        :key="index"
                        @click="handleClick({index, item})"
                        :class="`grid-content bg-purple${activeName === item.mixstation_code ? ' contene_active' : ''}`"
                        :title="item.mixstation_name"
                    >
                        <div :class="`grid-title${activeName === item.mixstation_code ? ' select-style' : ''}`">
                            {{ item.mixstation_name }}
                        </div>
                        <div class="select-style-bottom" v-show="activeName === item.mixstation_code">
                            <div class="bottom_div"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="operationArea el_right">
                <div class="timeSlot">
                    <el-date-picker
                        size="small"
                        v-model="timeSlot"
                        @change="timeChange"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm"
                    >
                    </el-date-picker>
                </div>
                <div class="setting-wrapper">
                    <el-popover
                        placement="bottom"
                        width="700"
                        trigger="click"
                        v-model="visibleDate"
                    >
                        <el-form
                            ref="dateSettingForm"
                            :model="dateSettingForm"
                            label-width="80px"
                            :inline="true"
                        >
                            <el-form-item label="往前天数">
                                <el-input
                                    type="number"
                                    min="0"
                                    v-model="dateSettingForm.filter_prev_day"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="往后天数">
                                <el-input
                                    type="number"
                                    min="0"
                                    v-model="dateSettingForm.filter_after_day"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="时间点">
                                <el-time-select
                                    placeholder="选择时间"
                                    v-model="dateSettingForm.filter_time"
                                    :picker-options="{
                                        start: '00:00',
                                        step: '00:10',
                                        end: '23:59'
                                    }"
                                >
                                </el-time-select>
                            </el-form-item>
                        </el-form>
                        <div style="text-align: right; margin: 0">
                            <el-button size="mini" type="text" @click="visibleDate = false">
                                取消
                            </el-button>
                            <el-button type="primary" size="mini" @click="settingTimeChange()">
                                确定
                            </el-button>
                        </div>
                        <el-button slot="reference" type="primary" size="small">
                            <i class="el-icon-setting"></i>设置默认时间
                        </el-button>
                    </el-popover>
                </div>
                <div class="time-btn">
                    <el-button
                        type="primary"
                        plain
                        size="small"
                        @click="getLastMonth"
                    >
                        上月
                    </el-button>
                    <el-button
                        type="primary"
                        plain
                        size="small"
                        @click="getWeek"
                    >
                        最近3天
                    </el-button>
                    <el-button
                        type="primary"
                        plain
                        size="small"
                        @click="getSameMonth"
                    >
                        本月
                    </el-button>
                </div>
            </div>
        </header>
        <div class="content_head">
            <div class="head_left">
                <ul class="Summary">
                    <li>
                        <span class="title_icon title_icon1"></span>
                        <span>当前任务数</span>
                        <strong>{{ statisticsData && statisticsData.task_count ? statisticsData.task_count : 0 }}</strong>
                    </li>
                    <li>
                        <span class="title_icon title_icon2"></span>
                        <span>任务方量</span>
                        <strong :title="statisticsData.order_volume">
                            {{ statisticsData && statisticsData.order_volume ? statisticsData.order_volume : 0 }}
                        </strong>
                    </li>
                    <li>
                        <span class="title_icon title_icon3"></span>
                        <span>完成方量</span>
                        <strong :title="statisticsData.complete_volume">
                            {{ statisticsData && statisticsData.complete_volume ? statisticsData.complete_volume : 0 }}
                        </strong>
                    </li>
                    <li>
                        <span class="title_icon title_icon4"></span>
                        <span>任务完成率</span>
                        <strong>{{ statisticsData && statisticsData.rate ? statisticsData.rate + '%' : 0 + '%' }}</strong>
                    </li>
                </ul>
                <div class="productionLine">
                    <table>
                        <tbody>
                            <tr>
                                <th style="white-space:nowrap;">
                                    <div>
                                        生产线
                                    </div>
                                </th>
                                <td v-for="(item, index) in mixstationlineData" :key="index">
                                    <div :class="`Line Line${index+1}`">
                                        {{ item.line_name || ' ' }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div>
                                        生产
                                    </div>
                                </th>
                                <td v-for="(item, index) in mixstationlineData" :key="index" :class="`td${index+1}`">
                                    <template v-if="mixstationlineCarData.product_car_list">
                                        <el-tooltip
                                            :disabled="isProductionTip"
                                            placement="bottom"
                                        >
                                            <div slot="content">
                                                <!-- <p>总方量：{{ productionTipData.total_volume }}</p>
                                                <br>
                                                <p>已打方量：{{ productionTipData.filled_volume }}</p> -->
                                                {{ productionTipData.filled_volume }}/{{ productionTipData.total_volume }}
                                            </div>
                                            <strong
                                                style="cursor: pointer;"
                                                @mouseenter="getProductionVehiclesPlayed(mixstationlineCarData.product_car_list[item.line_code])"
                                                @click="redayCarChange(mixstationlineCarData.product_car_list[item.line_code])"
                                            >
                                                <i
                                                    class="iconfont iconfei"
                                                    v-if="['4','5'].includes(mixstationlineCarData.product_car_list[item.line_code] &&
                                                        mixstationlineCarData.product_car_list[item.line_code].gk_sync_status)"
                                                >
                                                </i>
                                                {{
                                                    mixstationlineCarData.product_car_list[item.line_code]
                                                        ?
                                                            mixstationlineCarData.product_car_list[item.line_code].shch
                                                        :
                                                            ""
                                                }}
                                            </strong>
                                        </el-tooltip>
                                    </template>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div>
                                        准备
                                    </div>
                                </th>
                                <td v-for="(item, index) in mixstationlineData" :key="index" :class="`td${index+1}`">
                                    <template v-if="mixstationlineCarData.reday_car_list">
                                        <span v-for="(reday_cars, k) in mixstationlineCarData.reday_car_list[item.line_code]" :key="`car_${k}`">
                                            <el-popover
                                                v-if="reday_cars.length>1"
                                                placement="right"
                                                trigger="click"
                                            >
                                                <div class="car-ls">
                                                    <p
                                                        v-for="(jtem,jndex) in reday_cars"
                                                        :key="jndex"
                                                        @click="redayCarChange(jtem)"
                                                        :class="strongRowClassName(jtem)"
                                                        style="cursor: pointer;white-space:nowrap;"
                                                    >
                                                        <el-link :underline="false">
                                                            {{ jtem.pcb_number }}
                                                        </el-link>
                                                    </p>
                                                </div>
                                                <strong
                                                    slot="reference"
                                                    style="cursor: pointer;white-space:nowrap;"
                                                    :class="strongRowClassName(reday_cars[0])"
                                                >
                                                    <i class="iconfont iconfei" v-if="['4','5'].includes(reday_cars[0].gk_sync_status)"></i>
                                                    {{ reday_cars[0].shch }}
                                                </strong>
                                            </el-popover>
                                            <strong
                                                v-else
                                                style="cursor: pointer;white-space:nowrap;"
                                                @click="redayCarChange(reday_cars[0])"
                                                :class="strongRowClassName(reday_cars[0])"
                                            >
                                                <i class="iconfont iconfei" v-if="['4','5'].includes(reday_cars[0].gk_sync_status)"></i>
                                                {{ reday_cars[0].shch }}
                                            </strong>
                                        </span>
                                    </template>
                                </td>
                            </tr>
                            <tr>
                                <th @click="drawerCar" style="cursor: pointer;">
                                    <div class="await">
                                        等待
                                    </div>
                                </th>
                                <td
                                    :colspan="mixstationlineData.length"
                                    class="await-car-list-wrapper"
                                    @mouseenter="awaitCarenter"
                                    @mouseleave="awaitCarleave"
                                >
                                    <div class="await-car-list-main">
                                        <!-- mixstationlineCarData.wait_car_list -->
                                        <template v-if="vehicleList">
                                            <strong
                                                :class="strongRowClassName2(item)"
                                                v-for="(item,index) in vehicleList"
                                                :key="index"
                                            >
                                                <span
                                                    v-show="item.is_surplus === 1"
                                                    class="tui_text"
                                                >退</span>{{ item.plate_small_number }}&nbsp;&nbsp;&nbsp;
                                            </strong>
                                        </template>
                                    </div>
                                    <div class="await-car-list-box" v-show="isAllCarWrapper">
                                        <template v-if="vehicleList">
                                            <strong
                                                :class="strongRowClassName2(item)"
                                                v-for="(item,index) in vehicleList"
                                                :key="index"
                                            >
                                                <span
                                                    v-show="item.is_surplus === 1"
                                                    class="tui_text"
                                                >退</span>{{ item.plate_small_number }}&nbsp;&nbsp;&nbsp;
                                            </strong>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="head_right">
                <div class="deliveryMode" v-loading="isLoading">
                    <div class="operation-btn">
                        <!-- <p @click="addList" title="新增">
                            <span title="新增" class="iconfont" v-if="!radioDataCheck">&#xe7a5;</span>
                            <span title="保存" class="iconfont iconbaocun" v-if="radioDataCheck"></span>
                        </p> -->
                        <!-- <p @click="refreshList">
                            <span title="刷新" class="iconfont">&#xe79f;</span>
                        </p> -->
                        <!-- <p class="removeCar" @click="openMessage('remove_car')">
                            <span title="删除" class="iconfont">&#xe611;</span>
                        </p> -->
                        <el-button class="btn1" type="primary" @click="addList">
                            <span
                                title="新增"
                                class="iconfont"
                                v-if="!radioDataCheck"
                                style="margin-right: .06rem"
                            >&#xe7a5;</span>
                            <span
                                title="保存"
                                class="iconfont iconbaocun"
                                v-if="radioDataCheck"
                                style="margin-right: .06rem"
                            ></span>
                            <span v-if="!radioDataCheck">派车</span>
                            <span v-if="radioDataCheck">保存</span>
                        </el-button>
                        <el-button class="btn2" type="primary" @click="refreshList">
                            <span title="刷新" class="iconfont">&#xe79f;</span>
                        </el-button>
                        <el-button class="btn2" type="danger" @click="openMessage('remove_car')">
                            <span title="删除" class="iconfont">&#xe611;</span>
                        </el-button>
                    </div>
                    <el-tabs type="border-card">
                        <el-tab-pane label="砼车发货">
                            <div slot="label" class="tcfh_title_icon_box">
                                <span class="tcfh_title_icon"></span>
                                砼车发货
                            </div>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>
                                            <div>
                                                发货车号：
                                            </div>
                                        </th>
                                        <td>
                                            <el-input
                                                v-model="plate_small_number"
                                                readonly
                                            >
                                                <el-button slot="append" @click="clickSelectWaitCar('1')" style="width: .76rem; ">
                                                    <i class="iconfont icona-fahuodaifahuo"></i>
                                                </el-button>
                                            </el-input>
                                        </td>
                                        <th>
                                            <div>
                                                砼方量：
                                            </div>
                                        </th>
                                        <td>
                                            <el-input
                                                controls-position="right"
                                                v-model="tongfl"
                                                :min="0"
                                                :max="tongflMax"
                                                type="number"
                                                @mousewheel.native.prevent
                                                @input="tflChange"
                                            ></el-input>
                                        </td>
                                        <th>
                                            <div>
                                                砂浆方量：
                                            </div>
                                        </th>
                                        <td>
                                            <el-input
                                                controls-position="right"
                                                :min="0"
                                                v-model="sjfl"
                                                type="number"
                                                @mousewheel.native.prevent
                                                @input="onInput(sjfl,'sjfl')"
                                                :disabled="sjflDisabled"
                                            ></el-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colspan="8" class="tr_border_th">
                                            <div class="tr_border"></div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div>
                                                生产机组：
                                            </div>
                                        </th>
                                        <td>
                                            <el-select
                                                v-model="productCode"
                                                placeholder="请选择"
                                                value-key="line_name"
                                                :title="productCode.line_name"
                                                @change="productChange"
                                            >
                                                <el-option
                                                    v-for="item in scfhmixstationlineData"
                                                    :key="item.line_name"
                                                    :label="item.line_name"
                                                    :value="item"
                                                >
                                                </el-option>
                                            </el-select>
                                        </td>
                                        <th>
                                            <div>
                                                来自退砼：
                                            </div>
                                        </th>
                                        <td>
                                            <el-input @focus="awakenAlert" v-model="lztt" placeholder="选择退砼"></el-input>
                                        </td>
                                        <th>
                                            <div>
                                                退砼方量：
                                            </div>
                                        </th>
                                        <td>
                                            <el-input
                                                v-model="ttfl"
                                                @input="onInput(ttfl,'ttfl')"
                                                placeholder="请输入内容"
                                                :readonly="!lztt"
                                            ></el-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colspan="8" class="tr_border_th">
                                            <div class="tr_border"></div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div>
                                                虚方：
                                            </div>
                                        </th>
                                        <td>
                                            <el-input v-model="xf" @input="onInput(xf,'xf')" placeholder="请输入内容"></el-input>
                                        </td>
                                        <th>
                                            <div>
                                                发货方量：
                                            </div>
                                        </th>
                                        <td>
                                            <el-input readonly="readonly" v-model="fhfl" placeholder="请输入内容"></el-input>
                                        </td>
                                        <th>
                                            <div>
                                                强度等级：
                                            </div>
                                        </th>
                                        <td>
                                            <el-input readonly="readonly" v-model="qddj"></el-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colspan="8" class="tr_border_th">
                                            <div class="tr_border"></div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div>
                                                司机：
                                            </div>
                                        </th>
                                        <td>
                                            <el-input readonly="readonly" v-model="sj" placeholder="请输入内容"></el-input>
                                        </td>
                                        <th>
                                            <div>
                                                联系电话：
                                            </div>
                                        </th>
                                        <td>
                                            <el-input
                                                readonly="readonly"
                                                v-model.number="lxdh"
                                                maxlength="11"
                                                placeholder="请输入内容"
                                            ></el-input>
                                        </td>
                                        <th>
                                            <div>
                                                备注：
                                            </div>
                                        </th>
                                        <td>
                                            <el-input v-model="remarks" placeholder="请输入备注"></el-input>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
        <div class="content">
            <div :class="['content_l', {'hideListWidth': !isShowList}]">
                <div class="projectTable">
                    <div class="tableSearch">
                        <div class="tableSearchBtns">
                            <el-button
                                type="primary"
                                plain
                                @click="openMessage('success_task')"
                                size="medium"
                            >
                                完成任务
                            </el-button>
                            <el-dropdown trigger="click">
                                <el-button
                                    type="primary"
                                    plain
                                    size="medium"
                                >
                                    泵车选择
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="pumpTruckDistribution">
                                        派发泵车
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native="pumpTruckRelation">
                                        关联泵车
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native="pumpTruckDelete">
                                        删除泵车
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <el-button
                                v-show="!fhBtnFlag"
                                @click="openMessage('stop_task')"
                                type="danger"
                                plain
                                size="medium"
                                class="suspend_btn"
                            >
                                暂停发货
                            </el-button>
                            <el-button
                                v-show="fhBtnFlag"
                                @click="openMessage('reset_task')"
                                type="primary"
                                plain
                                size="medium"
                            >
                                重启发货
                            </el-button>
                            <el-button
                                type="primary"
                                plain
                                size="medium"
                                @click="updateDate"
                            >
                                更新日期
                            </el-button>
                            <el-button
                                type="primary"
                                plain
                                size="medium"
                                @click="pullWater"
                            >
                                拉水
                            </el-button>
                            <el-button
                                type="primary"
                                plain
                                size="medium"
                                @click="multipleOrderOneCar"
                            >
                                一车多单
                            </el-button>
                        </div>
                        <div class="search-wrapper tableSearchFilter">
                            <el-select v-model="product_status" placeholder="请选择" @change="productStatusChange">
                                <el-option :value="0" label="全部">
                                </el-option>
                                <el-option :value="1" label="待生产">
                                </el-option>
                                <el-option :value="2" label="生产中">
                                </el-option>
                                <el-option :value="4" label="已暂停">
                                </el-option>
                            </el-select>
                            <div class="sousuoicon">
                                <el-input
                                    placeholder="搜索..."
                                    v-model="sousuokuang"
                                    @click="searchList"
                                    size="mini"
                                >
                                    <span
                                        slot="suffix"
                                        class="iconfont"
                                        @click="searchList"
                                        style="cursor:pointer;"
                                    >&#xe683;</span>
                                </el-input>
                            </div>
                            <tableHeaderSet
                                cache-key="productDeliveryTable"
                                :check-header.sync="showHeader"
                            >
                            </tableHeaderSet>
                        </div>
                    </div>
                    <div class="table-bg table-bg2 table-bg-shipments">
                        <template>
                            <el-table
                                border
                                height="100%"
                                id="applyTable"
                                ref="applyTable"
                                style="width: 100%"
                                :data="shipmentsData"
                                tooltip-effect="dark"
                                @row-click="clickHandle1"
                                @header-dragend="colChange"
                                v-if="applyTable"
                                highlight-current-row
                            >
                                <el-table-column
                                    prop="remarks"
                                    fixed="left"
                                    label="备注"
                                    :width=" applyTableColWidths['备注'] || 150"
                                    show-overflow-tooltip
                                    v-if="showHeader['备注']"
                                >
                                </el-table-column>
                                <!-- <el-table-column
                                    fixed="left"
                                    label="选择"
                                    :width=" applyTableColWidths['选择'] || 50"
                                    v-if="showHeader['选择']"
                                >
                                    <template slot-scope="scope">
                                        <el-radio
                                            v-model="radioTaskChenked"
                                            :label="scope.row.task_number"
                                        >
                                        </el-radio>
                                    </template>
                                </el-table-column> -->
                                <el-table-column
                                    prop="task_number"
                                    label="任务单号"
                                    :width=" applyTableColWidths['任务单号'] || 180"
                                    :highlight-current-row="true"
                                    show-overflow-tooltip
                                    fixed="left"
                                    v-if="showHeader['任务单号']"
                                >
                                    <template class="car-status-temp" slot-scope="scope">
                                        <i v-show="scope.row.product_status === 4" class="el-icon-video-play" style="color:red"></i>
                                        <el-popover
                                            placement="right"
                                            width="400"
                                            trigger="click"
                                        >
                                            <div class="task-message_wrapper">
                                                <p>任务单号：<span>{{ scope.row.task_number }}</span></p>
                                                <p>客户名称：<span>{{ scope.row.customer_name }}</span></p>
                                                <p>工程名称：<span>{{ scope.row.project_name }}</span></p>
                                                <p>泵车物流公司：<span>{{ scope.row.pump_company_name }}</span></p>
                                                <p>施工部位：<span>{{ scope.row.place_detail }}</span></p>
                                                <p>产品标号：<span>{{ scope.row.strength_grade_name+' '+scope.row.special_require_name }}</span></p>
                                                <p>到货时间：<span>{{ scope.row.arrival_time }}</span></p>
                                                <p>联系人：<span>{{ scope.row.xclxr }}</span></p>
                                                <p>联系电话：<span>{{ scope.row.xclxr_phone }}</span></p>
                                                <p>已完成：<span>{{ scope.row.grand_total_quantity }}</span></p>
                                                <p>泵车：<span>{{ scope.row.pump_plate_number_mark +' '+scope.row.pump_truck_name }}</span></p>
                                                <p>浇筑方式：<span>{{ scope.row.pouring_name }}</span></p>
                                                <p>备注：<span>{{ scope.row.remarks }}</span></p>
                                                <p>限高：<span>{{ scope.row.limited_height }}</span></p>
                                                <p>限方：<span>{{ scope.row.limited_volume }}</span></p>
                                            </div>
                                            <span
                                                slot="reference"
                                                :class="tableRowClassName(scope.row.mixture_status)"
                                            >
                                                {{ scope.row.task_number }}
                                            </span>
                                        </el-popover>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="customer_name"
                                    label="客户名称"
                                    :width=" applyTableColWidths['客户名称'] || 200"
                                    show-overflow-tooltip
                                    v-if="showHeader['客户名称']"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="sgdw_name"
                                    label="施工单位"
                                    :width=" applyTableColWidths['施工单位'] || 200"
                                    show-overflow-tooltip
                                    v-if="showHeader['施工单位']"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="project_name"
                                    label="工程名称"
                                    :width=" applyTableColWidths['工程名称'] || 200"
                                    show-overflow-tooltip
                                    v-if="showHeader['工程名称']"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="place_detail"
                                    label="施工部位"
                                    :width=" applyTableColWidths['施工部位'] || 160"
                                    show-overflow-tooltip
                                    v-if="showHeader['施工部位']"
                                >
                                </el-table-column>
                                <el-table-column
                                    label="产品标号"
                                    :width=" applyTableColWidths['标号'] || 140"
                                    show-overflow-tooltip
                                    v-if="showHeader['标号']"
                                >
                                    <template slot-scope="scope">
                                        <span>
                                            {{ scope.row.strength_grade_name+' '+scope.row.special_require_name }}
                                        </span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="arrival_time"
                                    label="到货时间"
                                    show-overflow-tooltip
                                    :width=" applyTableColWidths['到货时间'] || 160"
                                    v-if="showHeader['到货时间']"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="pouring_name"
                                    label="浇筑方式"
                                    :width=" applyTableColWidths['浇筑方式'] || 140"
                                    v-if="showHeader['浇筑方式']"
                                >
                                </el-table-column>
                                <el-table-column
                                    label="泵车"
                                    :width=" applyTableColWidths['泵车'] || 120"
                                    v-if="showHeader['泵车']"
                                >
                                    <template slot-scope="scope">
                                        <span>
                                            {{ scope.row.pump_plate_number_mark +' '+scope.row.pump_truck_name }}
                                        </span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="quantity"
                                    label="任务方量"
                                    show-overflow-tooltip
                                    :width=" applyTableColWidths['任务方量'] || 120"
                                    v-if="showHeader['任务方量']"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="grand_total_complete_quantity"
                                    label="完成方量"
                                    show-overflow-tooltip
                                    :width=" applyTableColWidths['完成方量'] || 120"
                                    v-if="showHeader['完成方量']"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="xclxr"
                                    label="联系人"
                                    show-overflow-tooltip
                                    :width=" applyTableColWidths['联系人'] || 120"
                                    v-if="showHeader['联系人']"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="xclxr_phone"
                                    label="联系电话"
                                    show-overflow-tooltip
                                    :width=" applyTableColWidths['联系电话'] || 120"
                                    v-if="showHeader['联系电话']"
                                >
                                    <template slot-scope="scope">
                                        <span :class="{link:callPhoneApi!==''}" @click="handleOpenCallPhone(scope.row)">
                                            {{ scope.row.xclxr_phone }}
                                        </span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="employee_name"
                                    label="业务员"
                                    show-overflow-tooltip
                                    :width=" applyTableColWidths['业务员'] || 120"
                                    v-if="showHeader['业务员']"
                                >
                                </el-table-column>
                            </el-table>
                        </template>
                    </div>
                </div>
                <button :class="`expansionAreaBtn${isShowList ? '' : ' icon_left'}`" @click="isShowList = !isShowList">
                    <i class="iconfont iconjiantou1"></i>
                </button>
            </div>
            <div class="contetn_r" v-if="isShowList">
                <!-- 发货明细 -->
                <div class="detailsOfDelivery">
                    <div class="top-block" style="display:flex">
                        <div class="fahuomingxi">
                            <h4>
                                <span class="fhmx_title_icon"></span>
                                发货明细
                            </h4>
                            <el-button
                                type="primary"
                                size="mini"
                                v-if="isCoordinationBtn"
                                @click="collaborativeData"
                            >
                                协同数据
                            </el-button>
                        </div>
                        <div class="four-top">
                            <div class="top-four-block">
                                <span>任务方量</span>
                                <strong>{{ fhmxCount.quantity || 0 }}</strong>
                            </div>
                            <div class="top-four-block">
                                <span>累计完成</span>
                                <strong>{{ fhmxCount.grand_total_complete_quantity || 0 }}</strong>
                            </div>
                            <div class="top-four-block">
                                <span>累计车次</span>
                                <strong>{{ fhmxCount.grand_total_vehicle || 0 }}</strong>
                            </div>
                            <div class="top-four-block">
                                <span>预发累计</span>
                                <strong>{{ fhmxCount.yf_grand_total_quantity || 0 }}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="table-bg table-bg-shipments">
                        <template>
                            <el-table
                                ref="multipleTable"
                                :data="fhmxList"
                                tooltip-effect="dark"
                                style="width: 100%"
                                highlight-current-row
                                @row-click="clickHandle3"
                                height="98%"
                                :row-class-name="tableRowClassName2"
                                :cell-class-name="tableCellClassName2"
                            >
                                <!-- <el-table-column
                                    width="55"
                                >
                                    <template slot-scope="scope">
                                        <el-radio
                                            v-model="radioDataCheck"
                                            :label="scope.row.pcb_number"
                                            @change="clickHandle2(scope.row)"
                                            :value="scope.row"
                                        >
                                        </el-radio>
                                    </template>
                                </el-table-column> -->
                                <el-table-column
                                    label="状态"
                                >
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.status === 7" style="color:red;cursor:pointer;" @click="modifyStatus(scope.row)">
                                            {{ scope.row.status | formatStatus }}</span>
                                        <span v-else>{{ scope.row.status | formatStatus }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="shch"
                                    label="车号"
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.shch }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="volume"
                                    label="发货方量"
                                    show-overflow-tooltip
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.volume }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="concrete_volume"
                                    label="本车方量(砼)"
                                    show-overflow-tooltip
                                    width="120"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="mortar_volume"
                                    label="本车方量(砂浆)"
                                    show-overflow-tooltip
                                    width="130"
                                >
                                </el-table-column>
                                <el-table-column
                                    label="累计方量"
                                    show-overflow-tooltip
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.ljfs }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="ljcs"
                                    label="累计车次"
                                    show-overflow-tooltip
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.ljcs }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    label="搅拌机"
                                    prop="line_name"
                                    show-overflow-tooltip
                                >
                                </el-table-column>
                                <el-table-column
                                    label="发货单号"
                                    width="200"
                                    show-overflow-tooltip
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.pcb_number }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    label="调度时间"
                                    width="160"
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.pcsj || '无' }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    label="打料时间"
                                    width="160"
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.product_time || '无' }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    label="详细"
                                    show-overflow-tooltip
                                    fixed="right"
                                    width="120"
                                >
                                    <template slot-scope="scope" class="last-td">
                                        <span @click.stop="lookMore(scope.row)" style="color:#006EFF;cursor:pointer;">详细</span>&nbsp;&nbsp;&nbsp;
                                        <span
                                            @click="lineChange(scope.row)"
                                            style="color:#FB8450;cursor:pointer;"
                                            v-if="[8,9].includes(scope.row.status)"
                                        >调整</span>
                                        <span
                                            style="color:#999;"
                                            v-if="![8,9].includes(scope.row.status)"
                                        >调整</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <template>
            <div v-show="clickCarInfo" class="information-car" ref="carInfo">
                <h5>车号:{{ getCarInfo && getCarInfo.shch ? getCarInfo.shch : '' }}</h5>
                <p><span class="iconfont">&#xe646;</span>车次：{{ getCarInfo && getCarInfo.ljcs ? getCarInfo.ljcs : '' }}</p>
                <p><span class="iconfont">&#xe61e;</span>状态：{{ carStatus }}</p>
                <p><span class="iconfont">&#xe632;</span>出厂：{{ carTime }}</p>
                <p><span class="iconfont">&#xe60b;</span>司机：{{ getCarInfo && getCarInfo.sjxm ? getCarInfo.sjxm : '' }}</p>
                <p><span class="iconfont">&#xe6c7;</span>电话：{{ getCarInfo && getCarInfo.sjsjh ? getCarInfo.sjsjh : '' }}</p>
            </div>
        </template>
        <div v-if="dialogFormVisible" class="dialog-form-visible">
            <div class="dialog-form-visible-center">
                <div class="dialog-form-visible-header">
                    <h1>详细信息</h1>
                    <span @click="lookClose"><i class="el-icon-close"></i></span>
                </div>
                <header style="width: 100%" v-show="productCode.is_surplus === 1 && productCode.handle_style === 3">
                    <button class="tuiT_btn" @click="sthFrom">
                        退砼
                    </button>
                </header>
                <div class="form-content">
                    <div>
                        <p>发货单编号</p>
                        <p>{{ deliverydetails.pcb_number }}</p>
                    </div>
                    <div>
                        <p>送货车号</p>
                        <p>{{ deliverydetails.shch }}</p>
                    </div>
                    <div>
                        <p>司机姓名</p>
                        <p>{{ deliverydetails.sjxm }}</p>
                    </div>
                    <div>
                        <p>毛重</p>
                        <p>{{ deliverydetails.gross_weight }}</p>
                    </div>
                    <div>
                        <p>皮重</p>
                        <p>{{ deliverydetails.tare_weight }}</p>
                    </div>
                    <div>
                        <p>进场时间</p>
                        <p>{{ deliverydetails.into_time }}</p>
                    </div>
                    <div>
                        <p>打料完成时间</p>
                        <p>{{ deliverydetails.product_complete_time }}</p>
                    </div>
                    <div>
                        <p>出场时间</p>
                        <p>{{ deliverydetails.out_time }}</p>
                    </div>
                    <div>
                        <p>到达时间</p>
                        <p>{{ deliverydetails.arrival_time }}</p>
                    </div>
                    <div>
                        <p>开始卸料时间</p>
                        <p>{{ deliverydetails.unload_time }}</p>
                    </div>
                    <div>
                        <p>完成卸料时间</p>
                        <p>{{ deliverydetails.unload_over_time }}</p>
                    </div>
                    <div>
                        <p>签收方量</p>
                        <p>{{ deliverydetails.sign_volume }}</p>
                    </div>
                    <div>
                        <p>卸货方量</p>
                        <p>{{ deliverydetails.unload_volume }}</p>
                    </div>
                    <div>
                        <p>过磅方量</p>
                        <p>{{ deliverydetails.weigh_volume }}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 修改转工地 -->
        <div v-if="dialogFormVisible2" class="dialog-form-visible">
            <div class="dialog-form-visible-center">
                <div class="dialog-form-visible-header">
                    <h1>转工地</h1>
                    <span @click="lookClose2"><i class="el-icon-close"></i></span>
                </div>
                <div class="table-content table-bg">
                    <div class="search-wrapper">
                        <button class="select_btn" @click="selectReplaceTaskNumber">
                            选择
                        </button>
                        <div>
                            <el-input v-model="searchValue" placeholder="请输入搜索内容...">
                                <template slot="append">
                                    <button class="search-btn" @click="searchChange()">
                                        <i class="el-icon-search"></i>
                                    </button>
                                </template>
                            </el-input>
                        </div>
                    </div>
                    <el-table
                        :data="replaceData"
                        tooltip-effect="dark"
                        style="width: 100%"
                        highlight-current-row
                        height="6rem"
                        @row-click="selectTaskNumber"
                        :header-row-style="{background: '#edf0f5 !important',color: '#022782'}"
                    >
                        <el-table-column
                            width="55"
                            fixed="left"
                        >
                            <template slot-scope="scope">
                                <el-radio
                                    v-model="TaskCheck"
                                    :label="scope.row.task_number"
                                >
                                </el-radio>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="task_number"
                            label="任务单号"
                            width="180"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="project_name"
                            label="工程名称"
                            width="200"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="strength_grade_name"
                            label="标号"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="place_detail"
                            label="施工部位"
                            width="160"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="gdylr_phone"
                            label="联系电话"
                            width="120"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="quantity"
                            label="任务"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="grand_total_quantity"
                            label="已完成"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="pouring_name"
                            label="浇筑方式"
                        >
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <!-- 调整弹框 -->
        <div class="dialog-form-visible_adjust" v-if="dialogFormVisible3">
            <div class="adjust-wrapper">
                <div class="adjust-wrapper_header">
                    <h1>调整弹框</h1>
                    <span @click="cancelformulaline"><i class="iconfont iconguanbi"></i></span>
                </div>
                <div class="adjust-wrapper_section">
                    <div class="main-body_content">
                        <div class="top-wrapper_message" v-if="isFinishedMaterial == false">
                            <h2>原单信息</h2>
                            <div class="message_content">
                                <el-row :gutter="0">
                                    <el-col :span="6">
                                        <div class="grid-content">
                                            <p><i class="iconfont iconwenjianxinxi"></i>发货单号：<span>{{ originalInfo.pcb_number }}</span></p>
                                        </div>
                                    </el-col>
                                    <el-col :span="6">
                                        <div class="grid-content">
                                            <p><i class="iconfont iconnumber"></i>发货车号：<span>{{ originalInfo.shch }}</span></p>
                                        </div>
                                    </el-col>
                                    <el-col :span="6">
                                        <div class="grid-content">
                                            <p>
                                                <i class="iconfont iconlifang"></i>砼方量：<span>
                                                    {{ originalInfo.concrete_volume }}
                                                </span>方
                                            </p>
                                        </div>
                                    </el-col>
                                    <el-col :span="6">
                                        <div class="grid-content">
                                            <p>
                                                <i class="iconfont iconlifang"></i>砂浆方量：<span>
                                                    {{ originalInfo.mortar_volume }}
                                                </span>方
                                            </p>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="0">
                                    <el-col :span="6">
                                        <div class="grid-content">
                                            <p><i class="iconfont iconshuiniche"></i>来自退砼：<span>{{ originalInfo.back_pcb_number }}</span></p>
                                        </div>
                                    </el-col>
                                    <el-col :span="6">
                                        <div class="grid-content">
                                            <p><i class="iconfont iconkongzhi"></i>生产机组：<span>{{ originalInfo.line_name }}</span></p>
                                        </div>
                                    </el-col>
                                    <el-col :span="6">
                                        <div class="grid-content">
                                            <p><i class="iconfont iconbiliduidietu"></i>虚方：<span>{{ originalInfo.virtual_volume }}</span>方</p>
                                        </div>
                                    </el-col>
                                    <el-col :span="6">
                                        <div class="grid-content">
                                            <p>
                                                <i class="iconfont iconcaozuo-renyuantiaopei"></i>发货方量：<span>
                                                    {{ originalInfo.volume }}
                                                </span>方
                                            </p>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="0">
                                    <el-col :span="6">
                                        <div class="grid-content">
                                            <p><i class="iconfont iconfenzu"></i>强度等级：<span>{{ originalInfo.strength_grade_name }}</span></p>
                                        </div>
                                    </el-col>
                                    <el-col :span="6">
                                        <div class="grid-content">
                                            <p><i class="iconfont iconshuiyoux-"></i>拉水：<span>{{ originalInfo.pull_water }}</span>方</p>
                                        </div>
                                    </el-col>
                                    <el-col :span="6">
                                        <div class="grid-content">
                                            <p><i class="iconfont iconsiji"></i>司机姓名：<span>{{ originalInfo.sjxm }}</span></p>
                                        </div>
                                    </el-col>
                                    <el-col :span="6">
                                        <div class="grid-content">
                                            <p><i class="iconfont icondianhua"></i>司机电话：<span>{{ originalInfo.sjsjh }}</span></p>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            <div class="discard-wrapper_bg" v-show="isInvalid && adjustType != '3'">
                                <img src="@/assets/images/productionManagement/zuofei.png" alt="">
                            </div>
                        </div>
                        <template v-if="originalInfo.status === 8 && ['4','5'].includes(originalInfo.gk_sync_status)">
                            <div class="nav-warpper_select">
                                <p>
                                    <span>已打方量：</span>
                                    <el-input
                                        v-model="formulaline.filled_volume"
                                        @input="filledVolumeInput"
                                        type="number"
                                        @mousewheel.native.prevent
                                        title=""
                                        :disabled="isAdjust"
                                    >
                                    </el-input>
                                </p>
                                <el-radio-group v-model="adjustType" :disabled="isAdjust">
                                    <el-radio label="1">
                                        换线
                                    </el-radio>
                                    <el-radio label="5" v-if="['5'].includes(originalInfo.gk_sync_status)">
                                        更换车辆
                                    </el-radio>
                                    <el-radio label="2">
                                        转工地
                                    </el-radio>
                                    <el-radio label="4">
                                        倒掉
                                    </el-radio>
                                </el-radio-group>
                                <el-button @click="isInvalid = true;isAdjust = true;" :disabled="isAdjust" :class="{'disabled_adjust': isAdjust}">
                                    原单作废
                                </el-button>
                            </div>
                        </template>
                        <template v-if="originalInfo.status === 8 && !['4','5'].includes(originalInfo.gk_sync_status)">
                            <div class="nav-warpper_select">
                                <p>
                                    <span>已打方量 </span>
                                    <el-input
                                        v-model="formulaline.filled_volume"
                                        type="number"
                                        @mousewheel.native.prevent
                                        title=""
                                        readonly
                                    >
                                    </el-input>
                                </p>
                                <el-radio-group v-model="adjustType">
                                    <el-radio label="3">
                                        加/减量
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </template>
                        <template v-if="originalInfo.status === 9">
                            <div class="nav-warpper_select">
                                <p>
                                    <span>已打方量 </span>
                                    <el-input
                                        v-model="formulaline.filled_volume"
                                        @input="filledVolumeInput"
                                        type="number"
                                        @mousewheel.native.prevent
                                        title=""
                                        :disabled="isAdjust"
                                    >
                                    </el-input>
                                </p>
                                <el-radio-group v-model="adjustType">
                                    <el-radio label="2">
                                        转工地
                                    </el-radio>
                                </el-radio-group>
                                <el-button
                                    @click="isInvalid = true;isAdjust = true;"
                                    :disabled="isAdjust"
                                    :class="{'disabled_adjust': isAdjust}"
                                    v-if="isFinishedMaterial == false"
                                >
                                    原单作废
                                </el-button>
                            </div>
                        </template>
                        <div class="top-wrapper_message" v-if="[8,9].includes(originalInfo.status)">
                            <div class="message-wrapper_mask" v-show="!isInvalid && adjustType != '3' && isFinishedMaterial != true"></div>
                            <h2>调整信息</h2>
                            <div class="no-message_content">
                                <template v-if="adjustType === '3'">
                                    <el-row :gutter="0">
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>发货单号 </span>
                                                    <el-input v-model="originalInfo.pcb_number" readonly></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>状态 </span>
                                                    <el-select v-model="originalInfo.status" disabled>
                                                        <el-option :value="1" label="未接单"></el-option>
                                                        <el-option :value="2" label="作废"></el-option>
                                                        <el-option :value="3" label="完成"></el-option>
                                                        <el-option :value="4" label="退砼"></el-option>
                                                        <el-option :value="5" label="已接单"></el-option>
                                                        <el-option :value="6" label="预警"></el-option>
                                                        <el-option :value="7" label="冻结"></el-option>
                                                        <el-option :value="8" label="打料开始"></el-option>
                                                        <el-option :value="9" label="打料完成"></el-option>
                                                    </el-select>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>发货车号 </span>
                                                    <el-input v-model="originalInfo.shch" readonly></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="0">
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>生产机组 </span>
                                                    <el-input v-model="originalInfo.line_name" readonly></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>司机 </span>
                                                    <el-input v-model="originalInfo.sjxm" readonly></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>强度等级 </span>
                                                    <el-input v-model="originalInfo.strength_grade_name" readonly></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="0">
                                        <el-col :span="6">
                                            <div class="grid-content" style="display:flex">
                                                <p>
                                                    <span>原发货方量 </span>
                                                </p>
                                                <p v-if="originalInfo.new_volume <= 0">
                                                    <el-input
                                                        v-model="originalInfo.volume"
                                                        readonly
                                                    >
                                                    </el-input>
                                                </p>
                                                <p v-else>
                                                    <el-input
                                                        v-model="originalInfo.new_volume"
                                                        readonly
                                                    >
                                                    </el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="8">
                                            <div class="grid-content" style="display:flex">
                                                <p>
                                                    <span>调整后发货方量 </span>
                                                </p>
                                                <p v-if="originalInfo.new_volume <= 0">
                                                    <el-input
                                                        v-model="originalInfo.new_volume"
                                                        readonly
                                                    >
                                                    </el-input>
                                                </p>
                                                <p v-else>
                                                    <el-input
                                                        v-model="originalInfo.volume"
                                                        readonly
                                                    >
                                                    </el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content"></div>
                                        </el-col>
                                    </el-row>
                                </template>
                                <template v-else>
                                    <el-row :gutter="0">
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>发货单号 </span>
                                                    <el-input v-model="originalInfo.pcb_number" readonly></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>砼方量 </span>
                                                    <el-input v-model="originalInfo.concrete_volume" readonly></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>砂浆方量 </span>
                                                    <el-input v-model="originalInfo.mortar_volume" readonly></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>拉水 </span>
                                                    <el-input v-model="originalInfo.pull_water" readonly></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="0">
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>来自退砼 </span>
                                                    <el-input v-model="originalInfo.back_pcb_number" readonly></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>退砼方量 </span>
                                                    <el-input v-model="originalInfo.back_volume" readonly></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>虚方 </span>
                                                    <el-input v-model="originalInfo.virtual_volume" readonly></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>发货方量 </span>
                                                    <el-input v-model="originalInfo.volume" readonly></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="0">
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>强度等级 </span>
                                                    <el-input v-model="originalInfo.strength_grade_name" readonly></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>司机 </span>
                                                    <el-input v-model="originalInfo.sjxm" readonly></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>电话 </span>
                                                    <el-input v-model="originalInfo.sjsjh" readonly></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                            </div>
                                        </el-col>
                                    </el-row>
                                </template>
                            </div>
                            <div class="yes-message_content">
                                <template v-if="adjustType === '1'">
                                    <el-row :gutter="0">
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>生产机组：</span>
                                                    <el-select placeholder="请选择" v-model="formulaline.line_name">
                                                        <el-option v-for="(item,index) in taskformulalineData" :value="item.line_name" :key="index">
                                                            {{ item.line_name }}
                                                        </el-option>
                                                    </el-select>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>转线砼方量：</span>
                                                    <el-input v-model="formulaline.transfer_volume" readonly type="number"></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>转线砂浆方量：</span>
                                                    <el-input v-model="formulaline.transfer_sj_volume" readonly type="number"></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </template>
                                <template v-if="adjustType === '5'">
                                    <el-row :gutter="0">
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>车号：</span>
                                                    <el-input
                                                        v-model="changeLicenseNumber"
                                                        readonly
                                                    >
                                                        <el-button slot="append" @click="clickSelectWaitCar('2')">
                                                            选择
                                                        </el-button>
                                                    </el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>换车砼方量：</span>
                                                    <el-input v-model="formulaline.transfer_volume" readonly type="number"></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>换车砂浆方量：</span>
                                                    <el-input v-model="formulaline.transfer_sj_volume" type="number"></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </template>
                                <template v-if="adjustType === '2'">
                                    <el-row :gutter="0">
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>任务编号：</span>
                                                    <el-input
                                                        readonly
                                                        v-model="changeSiteInfo.task_number"
                                                        placeholder="请点击选择转工地任务"
                                                        @focus="replaceTask"
                                                    ></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>工程名称：</span>
                                                    <el-input readonly v-model="changeSiteInfo.project_name"></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </template>
                                <template v-if="adjustType === '3'">
                                    <el-row :gutter="0">
                                        <el-col :span="4">
                                            <div class="grid-content">
                                                <p>
                                                    <el-radio-group v-model="computeType" @change="computeTypeChange" :disabled="!canAdjustVolume">
                                                        <el-radio label="1">
                                                            加量
                                                        </el-radio>
                                                        <el-radio label="2">
                                                            减量
                                                        </el-radio>
                                                    </el-radio-group>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>砼方量：</span>
                                                    <el-input
                                                        v-model="computeAmountInfo.concrete_volume"
                                                        type="number"
                                                        min="0"
                                                        @mousewheel.native.prevent
                                                        :disabled="formulaline.filled_volume>=originalInfo.concrete_volume && !canAdjustVolume"
                                                        title=""
                                                    >
                                                    </el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="grid-content">
                                                <p>
                                                    <span>砂浆方量：</span>
                                                    <el-input
                                                        v-model="computeAmountInfo.mortar_volume"
                                                        type="number"
                                                        min="0"
                                                        :max="originalInfo.mortar_volume"
                                                        :disabled="formulaline.filled_volume
                                                            >=
                                                            (originalInfo.concrete_volume+
                                                            (originalInfo.new_mortar_volume||originalInfo.mortar_volume))
                                                            && !canAdjustVolume"
                                                        @mousewheel.native.prevent
                                                        title=""
                                                    ></el-input>
                                                </p>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="adjust-wrapper_footer">
                    <el-button
                        v-if="!(adjustType === '3'&&!canAdjust)"
                        type="primary"
                        :loading="btnLoading"
                        class="btn confirm_btn"
                        :class="{'send-btn': isInvalid || adjustType === '3' || isFinishedMaterial}"
                        @click="sendChange"
                    >
                        发送
                    </el-button>
                    <el-button class="btn" @click="cancelformulaline">
                        关闭
                    </el-button>
                </div>
            </div>
        </div>

        <!-- 更换车辆确认 -->
        <el-dialog
            title="确认更换车辆?"
            :visible.sync="replaceVehicleShow"
            width="30%"
            custom-class="changeEject"
        >
            <template>
                <h4>换车原因：</h4>
                <el-radio-group v-model="changeReason">
                    <el-radio label="车辆临时故障">
                        车辆临时故障
                    </el-radio>
                    <el-radio label="需方要求">
                        需方要求
                    </el-radio>
                    <el-radio label="最后一车">
                        最后一车
                    </el-radio>
                    <el-radio label="方量匹配">
                        方量匹配
                    </el-radio>
                </el-radio-group>
            </template>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelTransfer">取 消</el-button>
                <el-button type="primary" @click="confirmTransfer">确 定</el-button>
            </span>
        </el-dialog>

        <div class="dialogCallPhone" v-show="dialogCallPhone">
            <div class="container-box">
                <div class="call-content">
                    <h2>拨打电话</h2>
                    <div class="item">
                        <label><i class="iconfont iconzuoji"></i>本机分号：</label>
                        <el-input v-model="clientPhone" @blur="setClientPhone"></el-input>
                    </div>
                    <div class="item">
                        <label><i class="iconfont icondianhua2"></i>司机电话：</label>
                        <el-input v-model="curDriverPhone" readonly></el-input>
                    </div>
                    <div class="btns">
                        <el-button
                            class="btn btn1"
                            @click="handleCallPhone"
                            :disabled="!clientPhone"
                            :loading="callPhoneLoading"
                        >
                            拨打电话
                        </el-button>
                        <el-button class="btn btn2" @click="dialogCallPhone=false">
                            取消
                        </el-button>
                    </div>
                </div>
            </div>
        </div>

        <!-- 砼车发货修改/新增确认信息弹出 -->
        <el-dialog
            width="30%"
            :title="onfirmationInfoTitle"
            custom-class="onfirmationInfo"
            :visible.sync="onfirmationInfoVisible"
            @close="onfirmationInfo_cancel"
        >
            <ul class="onfirmationInfoContent">
                <li>
                    <h4>工程名称</h4>
                    <p>{{ radioData.project_name }}</p>
                </li>
                <li>
                    <h4>客户名称</h4>
                    <p>{{ radioData.customer_name }}</p>
                </li>
                <li>
                    <h4>施工部位</h4>
                    <p>{{ radioData.place_detail }}</p>
                </li>
                <li>
                    <h4>产品标号</h4>
                    <p>{{ radioData.strength_grade_name }}</p>
                </li>
                <li>
                    <h4>本车发货方量</h4>
                    <p>{{ fhfl }}</p>
                </li>
                <li>
                    <h4>车号(车辆代号)</h4>
                    <p>{{ plate_small_number }}</p>
                </li>
                <li>
                    <h4>车次</h4>
                    <p v-if="radioDataCheck">
                        {{ fhmxCount.grand_total_vehicle }}
                    </p>
                    <p v-else>
                        {{ fhmxCount.grand_total_vehicle + 1 }}
                    </p>
                </li>
            </ul>
            <span slot="footer" class="dialog-footer">
                <el-button @click="onfirmationInfo_cancel">取 消</el-button>
                <el-button type="primary" @click="onfirmationInfo_confirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import _ from 'loadsh';
import { eventBus } from '@/common/eventBus.ts';
// import blockColor from './blockColor';
import NP from 'number-precision';
NP.enableBoundaryChecking(false);
import md5 from 'js-md5';
import tableHeaderSet from '../component/cacheTableHeaderSet';
import currencyFun from '@/assets/js/currencyFun';


export default {
    // 生产发货
    name: 'production-delivery',
    components: {
        // blockColor,
        tableHeaderSet,
    },
    props: {},
    data() {
        return {
            // 页面加载状态
            initLoading: false,
            // 站点数据集合
            siteData: [],
            dialogFormVisible: false,
            dialogFormVisible2: false,
            dialogFormVisible3: false,
            // dialogFormVisible4: false,
            // 生产线-车辆数据
            mixstationlineData: [],
            scfhmixstationlineData: [],
            mixstationlineCarData: {},
            // 生产线tooltip是否显示
            isProductionTip: false,
            // 生产线tooltip数据
            productionTipData: {
                filled_volume: 0,
                total_volume: 0,
            },
            // 当前选择场站id
            activeName: '',
            // 时间段
            timeSlot: [],
            // 生产发货明细
            pageData: [],
            // 生产排期列表
            shipmentsData: [],
            // 发货车辆
            deliverCar: [],
            // 生产线
            productCode: {},
            // 强度等级
            strengthCode: {},
            // 搜索狂绑定值
            sousuokuang: '',
            // 生产任务统计信息
            statisticsData: {},
            clickCarInfo: false,
            // 砼车发货需要的v-model
            tongfl: 0,
            tongflMax: 2,
            sjfl: 0,
            lztt: '',
            ttfl: 0,
            xf: 0,
            sj: '',
            lxdh: '',
            ls: 0,
            qddj: '',
            remarks: '',
            clrz: 0,
            // 发货单编号
            pcbh: '',
            // 当前选中任务
            radioData: {},
            // 发货明细列表选中
            radioData2: {},
            // 当前选中行
            isSelect: 0,
            topClickInd: 0,

            // 发货明细列表
            fhmxList: [],
            // 车辆信息
            carInformation: '',
            getCarInfo: {},
            carStatus: '',
            carTime: '',
            radioDataCheck: null,
            // 收货明细详情
            deliverydetails: {},
            blockColorFlag: false,
            taskNumber: '',
            // 转工地选择的任务单号
            TaskCheck: '',
            // 转工地list
            replaceData: [],
            taskItem: '',
            vehicle_type_code: '', // 车辆编码code
            vehicle_type: '', // 车辆类型,
            fhBtnFlag: false, // 暂停发货，重启发货
            sjflDisabled: true, // 砂浆方量是否可输入
            taskformulalineData: [], // 换线可选择的数据
            radioTaskChenked: '', // 选中的任务单号
            formulaline: {
                volume: 0,
                filled_volume: 0,
                transfer_volume: 0,
                transfer_sj_volume: 0,
            }, // 换线提交的表单对象
            rules: {
                filled_volume: [
                    { required: true, message: '请输入已打方量', trigger: 'blur' },
                    { pattern: /^\d+(\.\d{0,2})?$|^\.\d{1,2}$/, message: '只能输入正整数，或两位小数' },
                ],
                line_code: [
                    { required: true, message: '请选择生产线', trigger: 'change' },
                ],
            },
            fhmxCount: {}, // 发货明细统计
            back_pcb_number: '', // 来自退砼选择的发货单编号
            product_status: 0, // 生产发货状态
            isLoading: false, // 控制添加修改发货单拦截
            searchValue: '',
            plate_number: '', //  车牌号
            dateSettingForm: {
                filter_prev_day: 0,
                filter_after_day: 0,
                filter_time: '00:00',
            },
            visibleDate: false,
            isFhCar: false, // 发货车号是否禁用
            adjustType: '1', // 调整类型
            isInvalid: false, // 原单是否作废
            originalInfo: {}, // 调整前原单信息
            changeSiteInfo: {}, // 转工地存放任务编号、工程名称
            computeType: '1', // 加减量类型
            computeAmountInfo: {
                concrete_volume: 0,
                mortar_volume: 0,
            }, // 加减量对象
            applyTableColWidths: {},
            applyTable: true,
            WaitCarActive: '', // 选中等待车辆的发货车号
            plate_small_number: '', // 发货时发货车号
            isAdjust: false, // 调整原单作废是否可点击
            showDialogWaitCarList: [], // 弹出等待车辆list
            isAllCarWrapper: false,
            // 调整弹框发送按钮状态
            btnLoading: false,
            // 是否显示协同数据按钮
            isCoordinationBtn: false,
            // 是否已打料
            isFinishedMaterial: false,

            /* 更换车辆 */
            // 车号字段
            changeLicenseNumber: '',
            // 是否是更换车辆弹出
            isReplaceVehicle: false,
            // 选中更换车辆
            selectedVehicles: {},

            /* 砼车发货 */
            // 默认车辆
            defaultVehicle: '',
            // 确认不使用默认车辆弹窗状态
            replaceVehicleShow: false,
            // 更换车辆原因
            changeReason: '',
            // 砼车发货当前选中车辆
            selectVehicle: {},
            isOpen: true,

            /* 过滤限高限方后车辆 */
            vehicleList: [],

            // 是否显示左侧任务列表
            isShowList: false,

            // 当前司机电话
            curDriverPhone: '',
            // 本机电话
            clientPhone: '',
            // 当前场站下 打电话地址
            callPhoneApi: '',
            // 拨打电话弹框
            dialogCallPhone: false,
            callPhoneLoading: false,
            // 能否调整
            canAdjust: true,

            // 缓存显示表头
            showHeader: {
                备注: true,
                颜色: true,
                选择: true,
                任务单号: true,
                客户名称: true,
                施工单位: true,
                工程名称: true,
                施工部位: true,
                标号: true,
                到货时间: true,
                浇筑方式: true,
                泵车: true,
                任务方量: true,
                完成方量: true,
                联系人: true,
                联系电话: true,
                业务员: true,
            },

            // 砼车发货修改/新增确认信息弹出
            onfirmationInfoVisible: false,
            onfirmationInfoTitle: '',

            // 是否能调整加减量
            canAdjustVolume: false,

            // signalr groupName
            groupName: '',
        };
    },
    computed: {
        fhfl: function () {
            const fhfl = Number(this.tongfl) + Number(this.sjfl) + Number(this.ttfl) + Number(this.xf) + Number(this.ls);
            const f = Math.round(fhfl * 100) / 100;
            const s = f.toString();
            return s;
        },
    },
    watch: {
        radioData: {
            deep: true,
            handler: function (newV) {
                if (Object.keys(newV).length > 0) {
                    if (newV.is_teamwork === 1) {
                        this.isCoordinationBtn = true;
                    } else {
                        this.isCoordinationBtn = false;
                    }
                }
            },
        },
        showHeader: {
            deep: true,
            handler: function () {
                this.colChange();
            },
        },
        lztt(val) {
            if (!val) {
                this.ttfl = 0;
            }
        },
    },
    created() {
        this.applyTableColWidths = JSON.parse(localStorage.getItem('applyTableColWidths')) || [];
        const _that = this;
        document.onkeydown = function () {
            const key = window.event.keyCode;
            if (key === 13) {
                _that.searchList();
            }
        };
        this.clientPhone = localStorage.getItem('clientPhone') || '';
    },
    methods: {
        // 是否允许更换车辆，不选择第一辆车
        async isAllowReplaceCar() {
            await this.$axios
                .get(`/interfaceApi/production/profile/constant/fkey/dispatch_cars_in_sequence/fability/${this.activeName}`)
                .then(res => {
                    // 请求返回fvalue等于0，不弹出更换车辆原因选择框，1和null弹出。
                    if (res && res.fvalue === '0') {
                        this.isOpen = false;
                    }
                })
                .catch(error => {
                    this.$message.error({ dangerouslyUseHTMLString: true, message: error.ErrorCode.Message });
                });
        },
        // 派发泵车
        pumpTruckDistribution() {
            if (this.radioTaskChenked) {
                this.$toast(
                    {
                        title: true,
                        text: '泵车选择',
                        type: 'eject',
                        width: '15rem',
                        t_url: 'tableList/index',
                        extr: {
                            code: {
                                TableCode: 'bcxz',
                                QueryString: 'task_number=' + this.radioTaskChenked,
                                selectedCallback: this.bcSelecthandle,
                            },
                        },
                    }
                );
            } else {
                this.$message.error('请先选择任务单');
            }
        },

        // 关联泵车
        pumpTruckRelation() {
            if (this.radioTaskChenked) {
                this.$toast(
                    {
                        title: true,
                        text: '泵车选择',
                        type: 'eject',
                        width: '15rem',
                        t_url: 'tableList/index',
                        extr: {
                            code: {
                                TableCode: 'task_glbc',
                                QueryString: `task_number=${this.radioTaskChenked}`,
                                selectedCallback: this.bcSelecthandle,
                            },
                        },
                    }
                );
            } else {
                this.$message.error('请先选择任务单');
            }
        },
        // 删除泵车
        pumpTruckDelete() {
            if (this.radioTaskChenked) {
                this.$toast(
                    {
                        title: true,
                        text: '删除泵车',
                        type: 'eject',
                        width: '15rem',
                        t_url: 'tableList/index',
                        extr: {
                            code: {
                                TableCode: 'task_del_pumper',
                                QueryString: 'task_number=' + this.radioTaskChenked,
                                selectedCallback: this.pumpTruckDeleteCallback,
                            },
                        },
                    }
                );
            } else {
                this.$message.error('请先选择任务单');
            }
        },
        // 派发泵车/关联泵车回调
        bcSelecthandle(data, type) {
            let postData = {};
            let messageText = '';
            if (type === 'pfbc') {
                postData = {
                    task_number: this.radioTaskChenked,
                    pump_plate_number: data.pump_plate_number,
                    pump_plate_number_mark: data.pump_plate_number_mark,
                    car_auto_no: data.car_auto_no,
                    pumper_height: data.pumper_height,
                    pumper_height_value: data.pumper_height_value,
                    pumper_station: data.station,
                    pumper_station_cur: data.station_cur,
                    car_type: data.car_type,
                    pumper_type: data.pumper_type,
                    dispatch_type: 1,
                    rel_task_number: null,
                };
                messageText = '派发成功！';
            } else if (type === 'glbc') {
                postData = {
                    task_number: this.radioTaskChenked,
                    pump_plate_number: data.pump_plate_number,
                    pump_plate_number_mark: data.pump_plate_number_mark,
                    car_auto_no: data.car_auto_no,
                    pumper_height: data.pumper_height,
                    pumper_height_value: data.pumper_height_value,
                    pumper_station: data.pumper_station,
                    pumper_station_cur: data.pumper_station_cur,
                    car_type: data.car_type,
                    pumper_type: data.pumper_type,
                    dispatch_type: 2,
                    rel_task_number: data.rel_task_number,
                };
                messageText = '关联成功！';
            }
            this.$axios
                .post('/interfaceApi/production/producttask/dispatch_pumper', postData)
                .then(() => {
                    this.$message({ type: 'success', message: messageText });
                    this.shipments(this.timeSlot[0], this.timeSlot[1]);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 删除泵车回调
        pumpTruckDeleteCallback() {
            this.shipments(this.timeSlot[0], this.timeSlot[1]);
        },
        awaitCarenter() {
            this.isAllCarWrapper = true;
        },
        awaitCarleave() {
            this.isAllCarWrapper = false;
        },
        // 点击生产车号文本框弹出选择
        async clickSelectWaitCar(type) {
            // 获取等待车辆
            await this.getwaitvehicle();
            if (type === '2') {
                // 是否是更换车辆弹出
                this.isReplaceVehicle = true;
            } else {
                this.isReplaceVehicle = false;
            }
            this.selectedVehicles = {};
            this.showDialogWaitCarList = this.vehicleList;
            this.$toast({
                title: true,
                text: '选择车辆',
                type: 'eject',
                width: '14rem',
                height: '8rem',
                t_url: 'productionManagement/productionDelivery/pullWaterForm/selectVehicle',
                extr: {
                    wait_car_list: this.showDialogWaitCarList,
                    WaitCarActive: this.plate_small_number,
                    sureCallback: this.selectWaitCarCallback,
                    closeCallback: this.deselectCarCallback,
                },
            });
        },
        // 选择车辆回调
        selectWaitCarCallback(data) {
            const _that = this;
            this.WaitCarActive = data.plate_small_number;
            if (this.WaitCarActive) {
                if (this.isReplaceVehicle) {
                    this.replaceVehicle(data);
                } else {
                    this.clrzChange(data);
                }
            } else {
                this.$message.error('请选择等待车辆');
            }
            // 重新绑定回车键查询
            document.onkeydown = function () {
                const key = window.event.keyCode;
                if (key === 13) {
                    _that.searchList();
                }
            };
        },
        colChange() {
            const applyTableColWidths = {};
            if (this.$refs.applyTable.columns) {
                this.$refs.applyTable.columns.forEach(col => {
                    // applyTableColWidths.push(col.width);
                    applyTableColWidths[col.label] = col.width;
                });
                localStorage.setItem('applyTableColWidths', JSON.stringify(applyTableColWidths));
            }
        },
        // 调整发送
        sendChange() {
            this.btnLoading = true;
            if (this.adjustType === '3') {
                this.sendChangeAdjustAmount(); // 加减量
            } else {
                if (this.isInvalid || this.isFinishedMaterial) {
                    if (this.adjustType === '1') {
                        this.sendChangeline(); // 换线
                    } else if (this.adjustType === '2') {
                        this.sendChangeSite(); // 转工地
                    } else if (this.adjustType === '4') {
                        this.sendChangeDelete(); // 软删除
                    } else if (this.adjustType === '5') {
                        this.confirmReplaceVehicle(); // 更换车辆
                    }
                }
                return;
            }

        },
        // 倒掉 软删除
        sendChangeDelete() {
            this.$axios
                .put('/interfaceApi/production/productpcb/dump/' + this.originalInfo.pcb_number)
                .then(() => {
                    this.$message.success('倒掉成功!');
                    this.dialogFormVisible3 = false;
                    this.shipments(this.timeSlot[0], this.timeSlot[1]);
                    this.mixstationlineCar();
                })
                .catch(error => {
                    this.btnLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 加减量
        async sendChangeAdjustAmount() {
            this.btnLoading = true;
            const data = {
                pcb_number: this.originalInfo.pcb_number,
                task_number: this.originalInfo.task_number,
                plate_number: this.originalInfo.plate_number,
                shch: this.originalInfo.shch,
                sjxm: this.originalInfo.sjxm,
                scbt: this.originalInfo.scbt,
                mixstation_code: this.originalInfo.mixstation_code,
                mixstation_name: this.originalInfo.mixstation_name,
                line_code: this.originalInfo.line_code,
                line_name: this.originalInfo.line_name,
                status: this.originalInfo.status,
                strength_grade: this.originalInfo.strength_grade,
                strength_grade_name: this.originalInfo.strength_grade_name,
                volume: this.originalInfo.volume,
                concrete_volume: this.originalInfo.concrete_volume,
                mortar_volume: this.originalInfo.mortar_volume,
                op_desc: this.computeType === '1' ? '加量' : '减量',
            };
            // 砼方量 - 已打放量；
            // const spare = this.originalInfo.concrete_volume - this.formulaline.filled_volume;
            // if (spare >= 0) { // 砼方量未打完
            //     data.concrete_volume = spare; // 剩余的砼方量
            // } else { // 砼方量已打完
            //     data.concrete_volume = 0;
            //     data.mortar_volume += spare; // 剩余的 砂浆方量
            // }
            if (this.computeType === '1') {
                data.add_concrete_volume = Number(this.computeAmountInfo.concrete_volume);
                data.add_mortar_volume = Number(this.computeAmountInfo.mortar_volume);
            } else {
                data.sub_concrete_volume = Number(this.computeAmountInfo.concrete_volume);
                data.sub_mortar_volume = Number(this.computeAmountInfo.mortar_volume);
            }

            const checkCallback = await this.additionSubtractionVerification(data);
            if (checkCallback) {
                let confirmDom = '<div>';
                confirmDom += `<p>1、${checkCallback}</p>`;
                confirmDom += '<p>2、加/减量前请于机操人员确认。</p>';
                confirmDom += '</div>';
                this.$confirm(confirmDom, '发送确认', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.sendChangeAdjustAmountRequest(data);
                }).catch(() => {
                    this.btnLoading = false;
                    this.$message({
                        type: 'info',
                        message: '已取消发送',
                    });
                });
            }
        },

        // 加减量前校验
        async additionSubtractionVerification(checkData) {
            return await this.$axios
                .post('/interfaceApi/production/productpcbadjustlog/preadjustlog', checkData)
                .then(res => {
                    return res;
                })
                .catch(error => {
                    this.btnLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 加减量请求
        sendChangeAdjustAmountRequest(data) {
            this.$axios
                .post('/interfaceApi/production/productpcbadjustlog/add', data)
                .then(res => {
                    this.btnLoading = false;
                    if (res) {
                        if (res.ResultCode === 1) {
                            this.$message.success('调整成功!');
                            this.dialogFormVisible3 = false;
                            this.shipments(this.timeSlot[0], this.timeSlot[1]);
                            this.mixstationlineCar();
                            this.computeAmountInfo = {
                                concrete_volume: 0,
                                mortar_volume: 0,
                            };
                        } else if (res.ResultCode === 2) {
                            this.$alert(`${res.Description}`, '管控提醒', {
                                dangerouslyUseHTMLString: true,
                                type: 'warning',
                                confirmButtonText: '确定',
                                callback: () => {
                                    this.$message.success('调整成功!');
                                    this.dialogFormVisible3 = false;
                                    this.shipments(this.timeSlot[0], this.timeSlot[1]);
                                    this.mixstationlineCar();
                                    this.computeAmountInfo = {
                                        concrete_volume: 0,
                                        mortar_volume: 0,
                                    };
                                },
                            });
                        } else if (res.ResultCode === 3) {
                            this.$alert(`${res.Description}`, '管控提醒', {
                                dangerouslyUseHTMLString: true,
                                type: 'warning',
                                confirmButtonText: '确定',
                                callback: () => {
                                    this.$message.success('调整失败!');
                                    this.dialogFormVisible3 = false;
                                    this.shipments(this.timeSlot[0], this.timeSlot[1]);
                                    this.mixstationlineCar();
                                    this.computeAmountInfo = {
                                        concrete_volume: 0,
                                        mortar_volume: 0,
                                    };
                                },
                            });
                        }
                        return;
                    }
                })
                .catch(error => {
                    this.btnLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 设置默认时间
        settingTimeChange() {
            this.visibleDate = false;
            this.$axios
                .post('/interfaceApi/production/producttask/save', this.dateSettingForm)
                .then(res => {
                    this.$message.success('设置成功');
                    const startTime = currencyFun.timeFormat({ date: new Date(res.start_time), fmt: 'yyyy-MM-dd HH:mm:ss' });
                    const endTime = currencyFun.timeFormat({ date: new Date(res.end_time), fmt: 'yyyy-MM-dd HH:mm:ss' });
                    this.timeSlot = [startTime, endTime];
                    this.init(this.timeSlot[0], this.timeSlot[1]);
                });
        },
        // 装工地列表搜索
        searchChange() {
            const fhmxList = this.fhmxList.filter(item => {
                return item.pcb_number === this.radioDataCheck;
            });
            this.$axios
                .post('/interfaceApi/production/producttask/shipmentsturntosite', {
                    station: this.activeName,
                    start_time: this.timeSlot[0],
                    end_time: this.timeSlot[1],
                    pageindex: 1,
                    pagesize: 200,
                    exclude_task_number: fhmxList[0].task_number,
                    strength_grade: fhmxList[0].strength_grade,
                    search: this.searchValue,
                })
                .then(res => {
                    this.replaceData = res.rows;
                });
        },
        // 输入已打方量，实时计算转线方量
        filledVolumeInput(val) {
            if (this.adjustType !== '1') {return;}
            if (val > this.formulaline.volume) {
                this.formulaline.filled_volume = this.formulaline.volume;
                this.formulaline.transfer_volume = 0;
                return;
            }
            if (val < 0) {
                this.formulaline.filled_volume = 0;
                this.formulaline.transfer_volume = this.formulaline.volume;
                return;
            }

            // 1、当已打方量<砼方量      转线砼方量＝砼方量－已打方量   转线砂浆方量＝砂浆方量
            // 2、当已打方量>砼方量      转线砼方量＝0                 转线砂浆方量＝砼方量＋砂浆方量－已打方量
            const playedVolume = Number(val);
            if (playedVolume < this.originalInfo.concrete_volume) {
                this.formulaline.transfer_volume = NP.minus(this.originalInfo.concrete_volume, playedVolume);
                this.formulaline.transfer_sj_volume = this.originalInfo.mortar_volume;
            } else {
                this.formulaline.transfer_volume = 0;
                // eslint-disable-next-line max-len
                this.formulaline.transfer_sj_volume = NP.minus(NP.plus(this.originalInfo.concrete_volume, this.originalInfo.mortar_volume), playedVolume);
            }
        },
        // 确定换线
        sendChangeline() {
            const row = this.taskformulalineData.filter(item => {
                return item.line_name === this.formulaline.line_name;
            });
            if (row.length > 0) {
                const data = {
                    pcb_number: this.originalInfo.pcb_number,
                    task_number: row[0].task_number,
                    line_code: row[0].line_code,
                    line_name: row[0].line_name,
                    scbt: row[0].scbt,
                    concrete_number: row[0].product_formula_number,
                    filled_volume: this.formulaline.filled_volume * 1,
                    transfer_volume: this.formulaline.transfer_volume,
                    transfer_sj_volume: this.formulaline.transfer_sj_volume * 1,
                };
                this.$axios
                    .post('/interfaceApi/production/productpcb/changedline/' + sessionStorage.getItem('clientID'), data)
                    .then(() => {
                        this.$message.success('换线成功');
                        this.dialogFormVisible3 = false;
                        this.detailsDelivery();
                        this.mixstationlineCar();
                    })
                    .catch(error => {
                        this.btnLoading = false;
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message.error('请选择要转线的生产线');
            }
        },
        // 取消换线
        cancelformulaline() {
            this.dialogFormVisible3 = false;
            this.taskformulalineData = [];
            this.formulaline = {
                volume: 0,
                filled_volume: 0,
                transfer_volume: 0,
                transfer_sj_volume: 0,
            };
            this.adjustType = ''; // 调整类型
            this.isInvalid = false; // 原单是否作废
            this.isAdjust = false;
            this.originalInfo = {}; // 调整前原单信息
            this.changeSiteInfo = {}; // 转工地存放任务编号、工程名称
            this.computeType = '1'; // 加减量类型
            this.computeAmountInfo = {
                concrete_volume: 0,
                mortar_volume: 0,
            }; // 加减量对象
        },
        // 换线功能检测任务单生产配比
        lineChange(row) {
            if (row.status === 9) {
                this.isFinishedMaterial = true;
            } else {
                this.isFinishedMaterial = false;
            }
            this.dialogFormVisible3 = true;
            this.originalInfo = row;

            this.$axios
                .get(`/interfaceApi/production/productpcb/filledvolume/${row.pcb_number}`)
                .then(res => {
                    if (row.status === 8 && ['4', '5'].includes(row.gk_sync_status)) {
                        this.adjustType = '1';
                        this.taskformulalineList(row);
                        this.formulaline.volume = row.volume;
                        this.formulaline.filled_volume = res;

                        // 1、当已打方量<砼方量      转线砼方量＝砼方量－已打方量   转线砂浆方量＝砂浆方量
                        // 2、当已打方量>砼方量      转线砼方量＝0                 转线砂浆方量＝砼方量＋砂浆方量－已打方量
                        if (res < row.concrete_volume) {
                            this.formulaline.transfer_volume = NP.minus(row.concrete_volume, res);
                            this.formulaline.transfer_sj_volume = row.mortar_volume;
                        } else {
                            this.formulaline.transfer_volume = 0;
                            this.formulaline.transfer_sj_volume = NP.minus(NP.plus(row.concrete_volume, row.mortar_volume), res);
                        }
                    } else if (row.status === 8 && !['4', '5'].includes(row.gk_sync_status)) {
                        this.adjustType = '3';
                        this.formulaline.filled_volume = res;
                        this.getCheckAdjust(row);
                    } else if (row.status === 9) {
                        this.adjustType = '2';
                        this.formulaline.filled_volume = res;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });

        },
        // 检查是否能调整
        getCheckAdjust(row) {
            this.$axios
                .get(`/interfaceApi/production/productpcbadjustlog/check/${row.pcb_number}`)
                .then(flag => {
                    if (flag) {
                        this.canAdjust = true;
                    } else {
                        this.canAdjust = false;
                    }
                })
                .catch(error => {
                    this.canAdjust = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 产线可换线的列表数据
        taskformulalineList(row) {
            this.$axios
                .get(`/interfaceApi/production/productformula/taskformulaline/${row.task_number}`)
                .then(res => {
                    this.taskformulalineData = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取最近三天日期
        getWeek() {
            const startDate = this.timeFormat(new Date(new Date().getTime() - 3600 * 1000 * 24 * 2), 'noFS') + ' 00:00:00';
            const endDate = this.timeFormat(new Date(), 'noFS') + ' 23:59:59';
            this.timeSlot = [startDate, endDate]; // 时间数组
            this.radioTaskChenked = '';
            this.init(this.timeSlot[0], this.timeSlot[1]);
            this.initData();
        },
        // 获取当月的日期
        getSameMonth() {
            const date = new Date();
            let year = date.getFullYear() + '';
            let month = date.getMonth() + 1 + '';
            if (month === 0) {
                month = 12;
                year = year - 1;
            }
            if (month < 10) {
                month = '0' + month;
            }
            // 本月第一天日期
            const begin = year + '-' + month + '-01 00:00:00';
            // 本月最后一天日期
            const lastDateOfCurrentMonth = new Date(year, month, 0);
            const end = year + '-' + month + '-' + lastDateOfCurrentMonth.getDate() + ' 23:59:59';
            this.timeSlot = [begin, end];
            this.radioTaskChenked = '';
            this.init(this.timeSlot[0], this.timeSlot[1]);
            this.initData();

        },
        // 获取上月的日期
        getLastMonth() {
            const nowdays = new Date();
            let year = nowdays.getFullYear();
            let month = nowdays.getMonth();
            if (month === 0) {
                month = 12;
                year = year - 1;
            }
            if (month < 10) {
                month = '0' + month;
            }
            const myDate = new Date(year, month, 0);
            const startDate = year + '-' + month + '-01 00:00:00'; // 上个月第一天
            const endDate = year + '-' + month + '-' + myDate.getDate() + ' 23:59:00';// 上个月最后一天
            this.timeSlot = [startDate, endDate];
            this.radioTaskChenked = '';
            this.init(this.timeSlot[0], this.timeSlot[1]);
            this.initData();
        },
        // 选择生产线 判断砂浆方量是否可输入
        productChange(val) {
            // 选择生产线触发的事件
            if (val.has_mortar === 0) {
                this.sjfl = 0;
                this.sjflDisabled = true;
            } else {
                // this.sjfl = 0;
                this.sjflDisabled = false;
            }
        },
        scfhmixstationline(task_number) {
            // 通过任务单号查询可操作的生产线
            this.scfhmixstationlineData = [];
            this.$axios
                .get(`/interfaceApi/production/productformula/formulaline/${task_number}`)
                .then(res => {
                    this.btnLoading = false;
                    this.scfhmixstationlineData = res;
                })
                .catch(error => {
                    this.initLoading = false;
                    this.btnLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 现在input 输入框只能输入整数或者两位小数
        onInput(value, name) {
            if (name === 'ls') {
                this.ls = value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : '';
            } else if (name === 'xf') {
                this.xf = value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : '';
            } else if (name === 'ttfl') {
                this.ttfl = value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : '';
            } else if (name === 'sjfl') {
                this.sjfl = value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : '';
            }
        },
        // 点击退砼 弹出配置表单
        sthFrom() {
            this.$YWShowLayer('FormCode=Form202002261153370256', this.productCode.pcbid);
        },
        // 发送确定转工地
        sendChangeSite() {
            this.$axios
                .post('/interfaceApi/production/productpcb/turntosite', {
                    pcb_number: this.originalInfo.pcb_number,
                    target_task_number: this.changeSiteInfo.task_number,
                    filled_volume: this.formulaline.filled_volume,
                    status: this.originalInfo.gk_sync_status,
                })
                .then(() => {
                    this.$message.success('转工地成功!');
                    this.dialogFormVisible3 = false;
                    this.shipments(this.timeSlot[0], this.timeSlot[1]);
                    this.mixstationlineCar();
                })
                .catch(error => {
                    this.btnLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 点击转工地table row 选中整行
        selectTaskNumber(row) {
            this.TaskCheck = row.task_number;
        },
        // 确定要转的工地
        selectReplaceTaskNumber() {
            if (this.TaskCheck) {
                const row = this.replaceData.filter(item => {
                    return item.task_number === this.TaskCheck;
                });
                this.dialogFormVisible2 = false;
                this.changeSiteInfo.task_number = this.TaskCheck;
                this.changeSiteInfo.project_name = row[0].project_name;
            } else {
                this.$message.error('请选择一个任务');
            }
        },
        // 转工地
        replaceTask() {
            if (this.radioDataCheck) {
                const fhmxList = this.fhmxList.filter(item => {
                    return item.pcb_number === this.radioDataCheck;
                });
                this.$axios
                    .post('/interfaceApi/production/producttask/shipmentsturntosite', {
                        station: this.activeName,
                        start_time: this.timeSlot[0],
                        end_time: this.timeSlot[1],
                        pageindex: 1,
                        pagesize: 200,
                        exclude_task_number: fhmxList[0].task_number,
                        strength_grade: fhmxList[0].strength_grade,
                        search: this.searchValue,
                    })
                    .then(res => {
                        this.dialogFormVisible2 = true;
                        this.replaceData = res.rows;
                    });
            }
        },
        // 冻结车辆解冻
        modifyStatus(row) {
            this.$confirm('确定要解冻该车辆?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .put(`/interfaceApi/production/productpcb/unfrozen/${row.pcb_number}`)
                    .then(() => {
                        this.$message.success('解冻成功!');
                        this.detailsDelivery();
                        this.mixstationlineCar();
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            });
        },
        // 点击准备车辆初始化对应的数据页面
        redayCarChange(item) {
            this.isSelect = _.findIndex(this.shipmentsData, function (o) { return o.task_number === item.task_number; });
            if (this.isSelect > -1) {
                this.radioData = this.shipmentsData[this.isSelect];
                this.radioTaskChenked = item.task_number;
                this.radioDataCheck = item.pcb_number;
                this.detailsDelivery(item.pcb_number);
                this.detailsDeliveryCount();
                this.scfhmixstationline(item.task_number);
            } else {
                this.$message.error('该车号对应的任务单不在列表中，无法定位');
            }

        },
        drawerCar() {
            this.$toast({
                title: true,
                text: '车辆状态管理',
                t_url: 'productionManagement/productionDelivery/dispatchManage/carStatusManage',
                extr: { closeBtn: {} },
            });
        },
        // 详细
        lookMore(item) {
            this.dialogFormVisible = true;
            this.$axios
                .get(`/interfaceApi/production/productpcb/deliverydetails/${item.pcb_number}`)
                .then(res => {
                    this.deliverydetails = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 关闭发货明细详细弹窗
        lookClose() {
            this.dialogFormVisible = false;
            this.deliverydetails = {};
        },
        // 关闭转工地
        lookClose2() {
            this.dialogFormVisible2 = false;
            this.replaceData = [];
            this.searchValue = '';
        },
        // 列表搜索
        searchList() {
            this.shipments(this.timeSlot[0], this.timeSlot[1]);
            this.statistics();
            this.initData();
        },
        // 根据任务单状态，查询任务单
        productStatusChange() {
            this.radioTaskChenked = '';
            this.shipments(this.timeSlot[0], this.timeSlot[1]);
            this.statistics();
            this.initData();
        },
        // 唤醒来自退砼弹窗
        awakenAlert() {
            const requestInfo = `project_code=${this.shipmentsData[this.isSelect].project_code}&mixstation_code=${this.activeName}`;
            this.$toast(
                {
                    title: true,
                    type: 'eject',
                    width: '15rem',
                    t_url: 'tableList/index',
                    extr: {
                        code: { TableCode: 'STHLZTT', QueryString: requestInfo, selectedCallback: this.productNumCallback },
                    },

                }
            );
        },
        productNumCallback(data) {
            const row = this.vehicleList.filter(item => {
                return item.plate_small_number === data.shch;
            });
            if (row.length > 0) {
                this.clrzChange(row[0]);
                this.ttfl = data.back_volume;
                this.back_pcb_number = data.back_pcb_number;
                this.lztt = data.back_pcb_number;
            }
        },

        // 大列表选择
        clickHandle1(row) {
            if (row.product_status === 4) {
                this.fhBtnFlag = true;
            } else {
                this.fhBtnFlag = false;
            }
            this.isSelect = _.findIndex(this.shipmentsData, function (o) { return o.ptid === row.ptid; });
            this.radioData = row;
            this.radioTaskChenked = row.task_number;
            this.taskNumber = this.radioData.task_number;
            this.taskItem = this.radioData;
            this.radioData2 = {};
            this.radioDataCheck = null;
            this.qddj = row.strength_grade_name;
            // this.$refs.blockcolor.initBlockCzColor(this.taskItem);
            this.detailsDelivery();
            this.detailsDeliveryCount();
            // this.mixstationlineCar();
            // 获取生产发货生产线
            this.scfhmixstationline(row.task_number);
            // 过滤车辆
            this.filteringVehicles();
            this.sjflDisabled = true;
            this.productCode = {};
            this.lztt = '';
            this.back_pcb_number = '';
            this.sjfl = 0;
            this.xf = 0;
            this.ls = 0;
            this.ttfl = 0;

            // 切换任务重新赋值默认选中第一辆车
            this.plate_small_number = this.defaultVehicle;
            // this.initData();
        },
        // 小列表选择
        clickHandle2(rows) {
            this.productCode = rows;
            this.pcbh = rows.pcb_number;
            this.task_number = rows.task_number;
            this.plate_small_number = rows.shch;
            this.sj = rows.sjxm;
            this.lxdh = rows.sjsjh;
            this.lztt = rows.back_pcb_number;
            this.back_pcb_number = rows.back_pcb_number;
            this.plate_number = rows.plate_number;
            this.tongfl = parseFloat(rows.concrete_volume);
            this.tongflMax = parseFloat(rows.concrete_volume) + 2;
            this.sjfl = rows.mortar_volume;
            this.ttfl = rows.back_volume;
            this.xf = rows.virtual_volume;
            this.ls = rows.pull_water;
            this.vehicle_type_code = rows.vehicle_type_code; // 车辆编码code
            this.vehicle_type = rows.vehicle_type; // 车辆类型
            this.remarks = rows.remarks_extend || '';
        },
        // 小列表选择
        clickHandle3(row) {
            this.radioDataCheck = row.pcb_number;
            this.radioData2 = row;
            this.productCode = row;
            this.pcbh = row.pcb_number;
            this.task_number = row.task_number;
            this.plate_small_number = row.shch;
            this.sj = row.sjxm;
            this.lxdh = row.sjsjh;
            this.lztt = row.back_pcb_number;
            this.back_pcb_number = row.back_pcb_number;
            this.plate_number = row.plate_number;
            this.tongfl = parseFloat(row.concrete_volume);
            this.tongflMax = parseFloat(row.concrete_volume) + 2;
            this.sjfl = row.mortar_volume;
            this.ttfl = row.back_volume;
            this.xf = row.virtual_volume;
            this.ls = row.pull_water;
            this.vehicle_type_code = row.vehicle_type_code; // 车辆编码code
            this.vehicle_type = row.vehicle_type; // 车辆类型
            this.remarks = row.remarks_extend || '';
        },
        initData() {
            this.productCode = {};
            this.strengthCode = {};
            this.pcbh = '';
            this.task_number = '';
            this.plate_small_number = '';
            this.sj = '';
            this.lxdh = '';
            this.plate_number = '';
            this.tongfl = 0;
            this.tongflMax = 2;
            this.sjfl = 0;
            this.ttfl = 0;
            this.xf = 0;
            this.ls = 0;
            this.blockCzColor = '';
        },
        // 时间转化
        timeFormat(date, status) {
            const y = date.getFullYear(); // 年
            let m = date.getMonth() + 1; // 月
            let d = date.getDate(); // 日
            m = m > 9 ? m : '0' + m;
            d = d > 9 ? d : '0' + d;
            if (status === 'noFS') {
                return `${y}-${m}-${d}`;
            }
            return `${y}-${m}-${d} 08:00`;
        },
        clrzChange(item) {
            this.plate_small_number = item.plate_small_number;
            this.sj = item.driver_name;
            this.lxdh = item.driver_phone;
            this.plate_number = item.plate_number;
            this.clrz = item ? item.bulk_density : this.deliverCar[0].bulk_density;
            this.tongfl = parseFloat(item.bulk_density);
            this.tongflMax = parseFloat(item.bulk_density) + 2;
            this.vehicle_type_code = item.vehicle_type_code; // 车辆编码code
            this.vehicle_type = item.vehicle_type; // 车辆类型
        },
        tflChange(value) {
            if (this.radioDataCheck) {return;}
            this.tongfl = value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : '';
            let MaxNumber = 0;
            const row = this.vehicleList.filter(item => {
                return item.plate_small_number === this.plate_small_number;
            });
            if (row.length > 0) {
                MaxNumber = this.jisuan(row[0].bulk_density, 2);
            }
            if (value > MaxNumber) {
                this.tongfl = MaxNumber;
            }
            if (value < 0) {
                this.tongfl = 0;
            }
        },
        jisuan(a, b) {
            return (a * 100 + b * 100) / 100;
        },
        openMessage(data) {
            let userClick;
            if (data === 'remove_car') {
                const fhmxList = this.fhmxList.filter(item => item.pcb_number === this.radioDataCheck );
                if (fhmxList.length > 0) {
                    if (fhmxList[0].status === 3 || fhmxList[0].status === 9) {
                        this.$message({
                            type: 'error',
                            message: '车辆无法删除！',
                        });
                        return;
                    }
                    userClick = '是否删除生产排车';

                } else {
                    this.$message({
                        type: 'error',
                        message: '请先选择要删除的生产排车',
                    });
                    return;
                }
            } else if (data === 'success_task') {
                userClick = '是否完成任务?';
            } else if (data === 'stop_task') {
                userClick = '是否暂停发货?';
            } else if (data === 'reset_task') {
                userClick = '是否启动发货?';
            }
            this.$confirm(`${userClick}`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                if (data === 'success_task') {
                    this.completeBtn();
                } else if (data === 'stop_task') {
                    this.suspendBtn();
                } else if (data === 'reset_task') {
                    this.resetBtn();
                } else {
                    this.removeCar();
                }
            });
        },
        // 更新日期
        updateDate() {
            if (this.radioTaskChenked) {
                this.$toast({
                    text: '选择更新时间',
                    title: true,
                    type: 'eject',
                    width: '6rem',
                    height: '3.5rem',
                    t_url: 'productionManagement/productionDelivery/updateDate/index',
                    extr: {
                        code: {},
                        sureCallback: this.sureSelectTime,
                    },
                });

            } else {
                this.$message.error('请先选择任务单');
            }
        },
        // 确认选择时间回调
        sureSelectTime(data) {
            this.$axios
                .get(`/interfaceApi/production/producttask/update/arrivaltime/${this.radioTaskChenked}/${data}`)
                .then(() => {
                    this.shipments(this.timeSlot[0], this.timeSlot[1]);
                    this.$message({
                        message: '更新成功！',
                        type: 'success',
                    });
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 发货明细列表-行，根据数据添加类名
        tableRowClassName2({ row }) {
            let newClass = '';
            if (row.is_surplus === 1 && row.handle_style === 3 && !row.is_forward) {
                newClass = 'check_row';
            }
            if (row.deleting === 1) {
                if (newClass !== '') {
                    newClass = [...newClass, 'deletingStyle'];
                    newClass = newClass.join(' ');
                } else {
                    newClass = 'deletingStyle';
                }
            }
            if (row.gk_sync_status === '6' || row.gk_sync_status === '7' || row.gk_sync_status === '11') {
                if (newClass !== '') {
                    newClass = [...newClass, 'abnormalStyle'];
                    newClass = newClass.join(' ');
                } else {
                    newClass = 'abnormalStyle';
                }
            }
            return newClass;
        },
        // 发货明细列表-单元格，根据数据添加类名
        tableCellClassName2({ row, columnIndex }) {
            if (row.turn_type === 3 && row.status === 3 && row.handle_style === 3) {
                if (columnIndex === 11) {
                    return 'check_cell_th';
                }
            }
            if (row.is_surplus === 1 && row.handle_style === 3 && !row.is_forward) {
                if (columnIndex === 11) {
                    return 'check_cell';
                }
            }
            return '';
        },
        tableRowClassName(status) {
            if (status === 0) {
                return 'error-color';
            }
            return '';
        },
        strongRowClassName(item) {
            // 未接单、已接单、预警（未接单）、冻结（未接单）
            if (item.status === 1) {
                return 'no-order';
            } if (item.status === 5) {
                return 'yes-order';
            } if (item.status === 6) {
                return 'warning-order';
            } if (item.status === 7) {
                return 'frozen-order';
            }
            return '';
        },
        strongRowClassName2(row) {
            if (row.is_lock === 1) {
                return 'frozen-order';
            } if (row.is_surplus === 1) {
                return 'tui_flag';
            }
            return 'orange-flag';
        },
        getDateStr(AddDayCount, status) {
            const dd = new Date();
            dd.setDate(dd.getDate() + AddDayCount);// 获取AddDayCount天后的日期
            const y = dd.getFullYear();
            const m = dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1;// 获取当前月份的日期，不足10补0
            const d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();// 获取当前几号，不足10补0
            if (status === 'noFS') {
                return `${y}-${m}-${d}`;
            }
            return y + '-' + m + '-' + d + ' 08:00';
        },
        // 选择时间
        timeChange() {
            this.init(this.timeSlot[0], this.timeSlot[1]);
        },
        showMessage(smallCar, task_number, data) {
            this.carInformation = smallCar;
            this.productpcbPage(smallCar, task_number, data, event);
        },
        // 刷新列列表
        refreshList() {
            this.radioData2 = {};
            this.radioDataCheck = null;
            this.isFhCar = false;
            this.shipments(this.timeSlot[0], this.timeSlot[1]);
        },
        // 砼车发货-新增/修改确认弹出
        addList() {
            if (this.shipmentsData.length > 0) {
                this.isLoading = true;
                if (this.shipmentsData[this.isSelect].mixture_status === 0) {
                    this.isLoading = false;
                    this.$message.error('请先关联配比');
                } else if (!this.WaitCarActive) {
                    this.isLoading = false;
                    this.$message.error('请选择发货车号');
                } else if (!this.productCode.line_name) {
                    this.isLoading = false;
                    this.$message.error('请选择生产线');
                } else if (!this.tongfl) {
                    this.isLoading = false;
                    this.$message.error('砼方量不能为空');
                } else if (this.radioDataCheck) {
                    this.onfirmationInfoTitle = '修改确认';
                    this.onfirmationInfoVisible = true;
                } else {
                    this.onfirmationInfoTitle = '新增确认';
                    this.onfirmationInfoVisible = true;
                }
            } else {
                this.$message.error('请先选择任务单！');
            }
        },

        // 砼车发货-新增/修改取消
        onfirmationInfo_cancel() {
            this.isLoading = false;
            this.onfirmationInfoVisible = false;
        },

        // 砼车发货-新增/修改确认
        onfirmationInfo_confirm() {
            this.onfirmationInfoVisible = false;
            if (this.radioDataCheck) {
                this.modifyCar();
            } else {
                this.addCarList();
            }
        },

        // 添加生产排车
        async addCarList() {
            const name = this.$takeTokenParameters('Name');
            // 当前选中车辆数据
            this.selectVehicle = this.vehicleList.filter(item => {
                return item.plate_small_number === this.plate_small_number;
            });
            if (this.selectVehicle.length > 0) {
                if (this.defaultVehicle !== this.plate_small_number && this.isOpen) {
                    this.replaceVehicleShow = true;
                } else {
                    const postData = {
                        special_require: this.shipmentsData[this.isSelect].special_require,
                        special_require_name: this.shipmentsData[this.isSelect].special_require_name,
                        project_code: this.shipmentsData[this.isSelect].project_code,
                        project_name: this.shipmentsData[this.isSelect].project_name,
                        haul_distance: this.shipmentsData[this.isSelect].haul_distance,
                        mixstation_code: this.activeName,
                        mixstation_name: this.siteData[this.topClickInd].mixstation_name,
                        line_code: this.productCode.line_code,
                        line_name: this.productCode.line_name,
                        task_number: this.shipmentsData[this.isSelect].task_number,
                        scbt: this.productCode.scbt || '一号线',
                        xdrw: name,
                        shch: this.selectVehicle[0].plate_small_number,
                        sjxm: this.sj,
                        sjsjh: this.lxdh,
                        plate_number: this.plate_number,
                        concrete_volume: this.tongfl,
                        mortar_volume: this.sjfl,
                        volume: this.fhfl,
                        sign_volume: 0,
                        back_volume: this.ttfl,
                        overage_volume: 0,
                        virtual_volume: this.xf,
                        pull_water: this.ls,
                        back_pcb_number: this.back_pcb_number,
                        panfs: this.productCode.plate_volume,
                        c1: 0,
                        c2: 0,
                        strength_grade: this.shipmentsData[this.isSelect].strength_grade,
                        strength_grade_name: this.shipmentsData[this.isSelect].strength_grade_name,
                        vehicle_type_code: this.vehicle_type_code, // 车辆编码code
                        vehicle_type: this.vehicle_type, // 车辆类型
                        // 调换车辆理由
                        change_reason: '',
                        remarks: this.remarks,
                    };
                    this.addCarListRequest(postData);
                }
            } else {
                this.isLoading = false;
                this.$message({
                    showClose: true,
                    message: '添加错误',
                    type: 'error',
                });
            }
        },
        // 砼车发货-取消换车
        cancelTransfer() {
            this.isLoading = false;
            this.replaceVehicleShow = false;
        },
        // 砼车发货-确认换车
        confirmTransfer() {
            if (this.changeReason === '') {
                this.$message.error('请先选择更换车辆原因');
            } else {
                const postData = {
                    special_require: this.shipmentsData[this.isSelect].special_require,
                    special_require_name: this.shipmentsData[this.isSelect].special_require_name,
                    project_code: this.shipmentsData[this.isSelect].project_code,
                    project_name: this.shipmentsData[this.isSelect].project_name,
                    haul_distance: this.shipmentsData[this.isSelect].haul_distance,
                    mixstation_code: this.activeName,
                    mixstation_name: this.siteData[this.topClickInd].mixstation_name,
                    line_code: this.productCode.line_code,
                    line_name: this.productCode.line_name,
                    task_number: this.shipmentsData[this.isSelect].task_number,
                    scbt: this.productCode.scbt || '一号线',
                    xdrw: name,
                    shch: this.selectVehicle[0].plate_small_number,
                    sjxm: this.sj,
                    sjsjh: this.lxdh,
                    plate_number: this.plate_number,
                    concrete_volume: this.tongfl,
                    mortar_volume: this.sjfl,
                    volume: this.fhfl,
                    sign_volume: 0,
                    back_volume: this.ttfl,
                    overage_volume: 0,
                    virtual_volume: this.xf,
                    pull_water: this.ls,
                    back_pcb_number: this.back_pcb_number,
                    panfs: this.productCode.plate_volume,
                    c1: 0,
                    c2: 0,
                    strength_grade: this.shipmentsData[this.isSelect].strength_grade,
                    strength_grade_name: this.shipmentsData[this.isSelect].strength_grade_name,
                    vehicle_type_code: this.vehicle_type_code, // 车辆编码code
                    vehicle_type: this.vehicle_type, // 车辆类型
                    // 调换车辆理由
                    change_reason: this.defaultVehicle + ',' + this.changeReason,
                    remarks: this.remarks,
                };
                this.addCarListRequest(postData);
                this.replaceVehicleShow = false;
                this.changeReason = '';
            }
        },
        // 添加生产排车请求
        async addCarListRequest(postData) {
            await this.$axios
                .post('/interfaceApi/production/productpcb/add/' + sessionStorage.getItem('clientID'), postData)
                .then(res => {
                    this.isLoading = false;
                    if (res) {
                        if (res.ResultCode === 1) {
                            this.$message.success('添加成功');
                            this.detailsDelivery();
                            this.mixstationlineCar();
                            this.detailsDeliveryCount();
                            this.xf = 0; // 其他
                            this.ls = 0; // 虚方
                            this.ttfl = 0; // 退砼方量
                            this.sjfl = 0; // 砂浆方量
                            this.lztt = '';
                            this.back_pcb_number = '';
                            this.productCode = {};
                            this.remarks = '';
                        } else if (res.ResultCode === 2) {
                            this.$alert(`${res.Description}`, '管控提醒', {
                                dangerouslyUseHTMLString: true,
                                type: 'warning',
                                confirmButtonText: '确定',
                                callback: () => {
                                    this.$message.success('添加成功');
                                    this.detailsDelivery();
                                    this.mixstationlineCar();
                                    this.detailsDeliveryCount();
                                    this.xf = 0; // 拉水
                                    this.ls = 0; // 虚方
                                    this.ttfl = 0; // 退砼方量
                                    this.sjfl = 0; // 砂浆方量
                                    this.lztt = '';
                                    this.back_pcb_number = '';
                                    this.productCode = {};
                                    this.remarks = '';
                                },
                            });
                        } else if (res.ResultCode === 3) {
                            this.$alert(`${res.Description}`, '管控提醒', {
                                dangerouslyUseHTMLString: true,
                                type: 'warning',
                                confirmButtonText: '确定',
                                callback: () => {
                                    this.$message.error('添加失败');
                                    this.detailsDelivery();
                                    this.mixstationlineCar();
                                    this.detailsDeliveryCount();
                                    this.xf = 0; // 拉水
                                    this.ls = 0; // 虚方
                                    this.ttfl = 0; // 退砼方量
                                    this.sjfl = 0; // 砂浆方量
                                    this.lztt = '';
                                    this.back_pcb_number = '';
                                    this.productCode = {};
                                    this.remarks = '';
                                },
                            });
                        }
                        return;
                    }
                    return;
                })
                .catch(error => {
                    this.isLoading = false;
                    this.$message({
                        showClose: true,
                        dangerouslyUseHTMLString: true,
                        message: error.ErrorCode.Message,
                        type: 'error',
                    });
                });
        },
        // 修改生产排车
        async modifyCar() {
            this.radioData2 = {};
            this.radioDataCheck = null;
            const name = this.$takeTokenParameters('Name');
            await this.$axios
                .post('/interfaceApi/production/productpcb/modify', {
                    pcb_number: this.pcbh,
                    mixstation_code: this.activeName,
                    mixstation_name: this.siteData[this.topClickInd].mixstation_name,
                    line_code: this.productCode.line_code,
                    line_name: this.productCode.line_name,
                    task_number: this.shipmentsData[this.isSelect].task_number,
                    haul_distance: this.shipmentsData[this.isSelect].haul_distance,
                    scbt: this.productCode.scbt || '一号线',
                    xdrw: name,
                    shch: this.plate_small_number,
                    sjxm: this.sj,
                    sjsjh: this.lxdh,
                    plate_number: this.plate_number,
                    concrete_volume: this.tongfl,
                    mortar_volume: this.sjfl,
                    volume: this.fhfl,
                    sign_volume: 0,
                    back_volume: this.ttfl,
                    overage_volume: 0,
                    virtual_volume: this.xf,
                    pull_water: this.ls,
                    back_pcb_number: this.back_pcb_number,
                    panfs: this.productCode.plate_volume,
                    c1: 0,
                    c2: 0,
                    strength_grade: this.shipmentsData[this.isSelect].strength_grade,
                    strength_grade_name: this.shipmentsData[this.isSelect].strength_grade_name,
                    vehicle_type_code: this.vehicle_type_code, // 车辆编码code
                    vehicle_type: this.vehicle_type, // 车辆类型
                    remarks: this.remarks,
                }).then( () => {
                    this.$message.success('修改成功');
                    this.isLoading = false;
                    this.detailsDelivery();
                    this.mixstationlineCar();
                    this.detailsDeliveryCount();
                    this.xf = 0; // 拉水
                    this.ls = 0; // 虚方
                    this.ttfl = 0; // 退砼方量
                    this.sjfl = 0; // 砂浆方量
                    this.lztt = '';
                    this.back_pcb_number = '';
                    this.productCode = {};
                    this.remarks = '';
                })
                .catch(error => {
                    this.isLoading = false;
                    this.$message({
                        showClose: true,
                        dangerouslyUseHTMLString: true,
                        message: error.ErrorCode.Message,
                        type: 'error',
                    });
                });
        },
        // 删除生产排车
        async removeCar() {
            await this.$axios
                .delete(`/interfaceApi/production/productpcb/remove2/${this.pcbh}/${sessionStorage.getItem('clientID')}`)
                .then(res => {
                    this.radioData2 = {};
                    this.radioDataCheck = null;
                    this.productCode = {};
                    this.$message.success(res);
                    this.detailsDelivery();
                    this.mixstationlineCar();
                    this.detailsDeliveryCount();
                    this.xf = 0; // 拉水
                    this.ls = 0; // 虚方
                    this.ttfl = 0; // 退砼方量
                    this.sjfl = 0; // 砂浆方量
                    this.lztt = '';
                    this.back_pcb_number = '';
                })
                .catch(error => {
                    this.$message({
                        showClose: true,
                        dangerouslyUseHTMLString: true,
                        message: error.ErrorCode.Message,
                        type: 'error',
                    });
                });
        },
        // 完成任务
        async completeBtn() {
            await this.$axios
                .put(`/interfaceApi/production/producttask/complete/${this.shipmentsData[this.isSelect].task_number}`)
                .then(() => {
                    this.$message({ type: 'success', message: '完成成功!' });
                    this.shipments(this.timeSlot[0], this.timeSlot[1]);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 暂停发货
        async suspendBtn() {
            await this.$axios
                .put(`/interfaceApi/production/producttask/handle/${this.shipmentsData[this.isSelect].task_number}/status?status=4`)
                .then(() => {
                    this.$message({ type: 'success', message: '暂停成功!' });
                    this.shipments(this.timeSlot[0], this.timeSlot[1]);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 重启发货
        async resetBtn() {
            await this.$axios
                .put(`/interfaceApi/production/producttask/handle/${this.shipmentsData[this.isSelect].task_number}/status?status=2`)
                .then(() => {
                    this.$message({ type: 'success', message: '重启成功!' });
                    this.shipments(this.timeSlot[0], this.timeSlot[1]);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取车辆状态
        async getCarStatus() {
            await this.$axios
                .get(`/interfaceApi/production/producttask/handle/${this.shipmentsData[this.isSelect].task_number}/status?status=4`)
                .then(() => {
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取车辆状态信息
        // eslint-disable-next-line max-params
        async productpcbPage(smallCar, taskNumber, status, event) {
            await this.$axios
                .get(`/interfaceApi/production/productpcb/info/${taskNumber}/${smallCar}/${status}`)
                .then(res => {
                    this.getCarInfo = res;
                    setTimeout(() => {
                        const infoDom = this.$refs.carInfo;
                        const domHeight = infoDom.offsetHeight;
                        const x = event.pageX + 10;
                        const y = event.pageY - domHeight;
                        infoDom.style.position = 'fixed';
                        infoDom.style.left = x + 'px';
                        infoDom.style.top = y + 'px';
                        this.clickCarInfo = true;
                    }, 100);
                    if (status === 1) {
                        this.carStatus = '正在打料';
                        this.carTime = res.product_time;
                    } else if (status === 2) {
                        this.carStatus = '送货中';
                        this.carTime = res.deliver_time;
                    } else {
                        this.carStatus = '已到达';
                        this.carTime = res.arrival_time;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 发货明细列表
        async detailsDelivery(pcb_number) {
            if (this.shipmentsData.length < 1) {
                return;
            }
            await this.$axios
                .post('/interfaceApi/production/productpcb/page', {
                    mixstation_code: this.activeName,
                    task_number: this.radioTaskChenked,
                    strength_grade: this.shipmentsData[this.isSelect].strength_grade,
                    pageindex: 1,
                    pagesize: 200,
                })
                .then(res => {
                    this.btnLoading = false;
                    this.fhmxList = res.rows;
                    if (pcb_number) {
                        const Index = _.findIndex(this.fhmxList, function (o) { return o.pcb_number === pcb_number; });
                        this.clickHandle2(this.fhmxList[Index]);
                    }
                })
                .catch(error => {
                    this.initLoading = false;
                    this.btnLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });

            return;
        },
        // 发货明细统计
        async detailsDeliveryCount() {
            await this.$axios
                .get('/interfaceApi/production/producttask/fhmxtj/' + this.radioTaskChenked)
                .then(res => {
                    this.btnLoading = false;
                    this.fhmxCount = res;
                })
                .catch(error => {
                    this.initLoading = false;
                    this.btnLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });

            return;
        },
        // 生产线任务统计信息
        async statistics() {
            await this.$axios
                .post('/interfaceApi/production/producttask/statistics', {
                    station: this.activeName,
                    statistics_date: this.timeSlot[0],
                    statistics_end_date: this.timeSlot[1],
                    product_status: this.product_status,
                    search: this.sousuokuang,
                    has_statistics: 1,
                })
                .then(res => {
                    this.statisticsData = res || {};

                })
                .catch(error => {
                    this.initLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
            return;
        },
        // 列表请求
        shipments(startTime, endTime) {
            this.initLoading = true;
            this.fhmxList = [];
            this.lztt = '';
            this.back_pcb_number = '';
            this.ttfl = 0;
            this.sjfl = 0;
            this.remarks = '';
            this.$axios
                .post('/interfaceApi/production/producttask/shipments', {
                    station: this.activeName,
                    start_time: startTime || this.getDateStr(-2),
                    end_time: endTime || this.getDateStr(1),
                    pageindex: 1,
                    pagesize: 200,
                    search: this.sousuokuang,
                    product_status: this.product_status,
                })
                .then(res => {
                    this.shipmentsData = res.rows;
                    // 获取生产发货明细
                    if (this.shipmentsData && this.shipmentsData.length > 0) {
                        if (this.radioTaskChenked) {
                            this.radioTaskChenked = this.radioTaskChenked;
                            const shipmentsRow = this.shipmentsData.filter(item => {
                                return item.task_number === this.radioTaskChenked;
                            });
                            if (shipmentsRow[0].product_status === 4) {
                                this.fhBtnFlag = true;
                            } else {
                                this.fhBtnFlag = false;
                            }
                            // 发货明细列表
                            this.detailsDelivery();
                            // 发货明细统计
                            this.detailsDeliveryCount();
                            // 通过任务单号查询可操作的生产线
                            this.scfhmixstationline(shipmentsRow[0].task_number);
                            this.qddj = shipmentsRow[0].strength_grade_name;
                            this.radioData = shipmentsRow[0];
                            this.taskNumber = shipmentsRow[0].task_number;
                            this.blockCzColor = shipmentsRow[0].block_cz_color;
                            this.taskItem = shipmentsRow[0];
                            // this.$refs.blockcolor.initBlockCzColor(this.taskItem);
                        } else {
                            this.radioTaskChenked = this.shipmentsData[0].task_number;
                            if (this.shipmentsData[0].product_status === 4) {
                                this.fhBtnFlag = true;
                            } else {
                                this.fhBtnFlag = false;
                            }
                            this.detailsDelivery();
                            this.detailsDeliveryCount();
                            this.scfhmixstationline(this.shipmentsData[0].task_number);
                            this.qddj = this.shipmentsData[0].strength_grade_name;
                            this.radioData = this.shipmentsData[0];
                            this.taskNumber = this.shipmentsData[0].task_number;
                            this.blockCzColor = this.shipmentsData[0].block_cz_color;
                            this.taskItem = this.shipmentsData[0];
                            // if (this.$refs.blockcolor) {
                            // this.$refs.blockcolor.initBlockCzColor(this.taskItem);
                            // }
                        }
                    } else {
                        this.taskItem = '';
                        // this.statisticsData = {};
                        this.fhmxCount = {};
                        this.scfhmixstationlineData = {};
                        this.qddj = '';
                    }
                    // 过滤车辆
                    this.filteringVehicles();
                    this.initLoading = false;
                })
                .catch(error => {
                    this.initLoading = false;
                    this.btnLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
            return;
        },
        // 获取生产线
        async mixstationline() {
            this.mixstationlineData = [];
            await this.$axios
                .get(`/interfaceApi/production/mixstationline/${this.activeName}`)
                .then(res => {
                    this.mixstationlineData = res || [];
                    if (this.mixstationlineData.length === 1) {
                        this.productCode = res[0].line_name;
                    }
                })
                .catch(error => {
                    this.initLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取生产线-生产车辆打料进度
        async getProductionVehiclesPlayed(item) {
            await this.$axios
                .get(`/interfaceApi/production/productpcb/filledprogress/${item.pcb_number}`)
                .then(res => {
                    this.productionTipData = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取生产线下的车辆
        async mixstationlineCar() {
            this.mixstationlineCarData = [];
            await this.$axios
                .get(`/interfaceApi/production/productpcb/vehicle/${this.activeName}`)
                .then(res => {
                    this.mixstationlineCarData = res;
                    // 同一车辆 下数据合并
                    const reday_car_list = this.formatMixstationlineCarStruct(res.reday_car_list);
                    this.mixstationlineCarData.reday_car_list = reday_car_list;

                    this.showDialogWaitCarList = res.wait_car_list || [];
                    this.filteringVehicles();
                    this.btnLoading = false;
                })
                .catch(error => {
                    this.initLoading = false;
                    this.btnLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 格式化生产线下的车辆结构
        formatMixstationlineCarStruct(car_list = {}) {
            const cars = Object.keys(car_list);
            cars.forEach(key => {
                const redaysLineCars = car_list[key];
                const carMaps = {};
                redaysLineCars.forEach(item => {
                    if (carMaps[item.plate_number]) {
                        carMaps[item.plate_number].push(item);
                    } else {
                        carMaps[item.plate_number] = [item];
                    }
                });
                const list = Object.keys(carMaps).map(carkey => carMaps[carkey]);
                car_list[key] = list;
            });
            return car_list;
        },
        // 过滤车辆
        filteringVehicles() {
            this.vehicleList = [];
            // 退砼车辆
            const concreteWithdrawing = this.mixstationlineCarData.wait_car_list.filter(item => {
                return item.is_surplus === 1;
            });
            // 退砼车辆排序
            concreteWithdrawing.sort(function (a, b) {
                return a.sort_num - b.sort_num;
            });

            // 非退砼车辆
            const vehicleData = this.mixstationlineCarData.wait_car_list.filter(item => {
                return item.is_surplus !== 1;
            });

            // 非退砼车辆排序
            vehicleData.sort(function (a, b) {
                return a.sort_num - b.sort_num;
            });

            let filteredVehicles = [];
            if (Object.keys(this.radioData).length !== 0) {
                if (this.radioData.limited_style === 1) {// 限高、限方
                    filteredVehicles = vehicleData.filter(item => {
                        return item.limited_height <= this.radioData.limited_height && item.bulk_density <= this.radioData.limited_volume;
                    });
                } else if (this.radioData.limited_style === 2) {// 限高
                    filteredVehicles = vehicleData.filter(item => {
                        return item.limited_height <= this.radioData.limited_height;
                    });
                } else if (this.radioData.limited_style === 3) {// 限方
                    filteredVehicles = vehicleData.filter(item => {
                        return item.bulk_density <= this.radioData.limited_volume;
                    });
                } else {
                    filteredVehicles = vehicleData;
                }
            } else {
                filteredVehicles = vehicleData;
            }
            this.vehicleList.push(...concreteWithdrawing, ...filteredVehicles);

            // 过滤等待车辆里是否有已选车辆
            const row = this.vehicleList.filter(item => item.plate_small_number === this.plate_small_number);
            if (row.length > 0) {
                return;
            }

            if (this.vehicleList.length > 0) {
                this.plate_small_number = this.vehicleList[0].plate_small_number;
                // 默认车辆
                this.defaultVehicle = this.vehicleList[0].plate_small_number;
                // 当前选中车辆数据
                this.selectVehicle = this.vehicleList[0];
                this.WaitCarActive = this.vehicleList[0].plate_small_number;
                this.sj = this.vehicleList[0].driver_name;
                this.lxdh = this.vehicleList[0].driver_phone;
                this.plate_number = this.vehicleList[0].plate_number;
                this.clrz = this.vehicleList[0].bulk_density;
                this.tongfl = parseFloat(this.vehicleList[0].bulk_density);
                this.tongflMax = parseFloat(this.vehicleList[0].bulk_density) + 2;
                this.vehicle_type_code = this.vehicleList[0].vehicle_type_code; // 车辆编码code
                this.vehicle_type = this.vehicleList[0].vehicle_type; // 车辆类型
            } else {
                this.sj = '';
                this.lxdh = '';
                this.plate_number = '';
                this.clrz = 0;
                this.plate_small_number = '';
                // 默认车辆
                this.defaultVehicle = '';
                // 当前选中车辆数据
                this.selectVehicle = {};
                this.WaitCarActive = '';
                this.tongfl = 0;
                this.tongflMax = 2;
                this.vehicle_type_code = ''; // 车辆编码code
                this.vehicle_type = ''; // 车辆类型
            }
            this.$forceUpdate();
        },
        // 获取等待车辆列表
        getwaitvehicle() {
            this.$axios
                .get(`/interfaceApi/production/productpcb/waitvehicle/${this.activeName}`)
                .then(res => {
                    this.mixstationlineCarData.wait_car_list = res.wait_car_list;
                    this.showDialogWaitCarList = res.wait_car_list || [];
                    // 过滤车辆
                    this.filteringVehicles();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取场站信息
        async gitSiteData() {
            await this.$axios
                .get('/interfaceApi/baseinfo/stationmanger/get_station_user/?userId=' + this.$takeTokenParameters('Uid'))
                .then(res => {
                    if (res.length > 0) {
                        // 当前站点默认站点
                        this.siteData = [];
                        res.forEach(item => {
                            this.siteData.push({
                                mixstation_code: item.station_id,
                                mixstation_name: item.name,
                                capacity: item.capacity,
                            });
                        });
                        this.activeName = this.siteData[0].mixstation_code;
                        // 是否允许更换车辆，不选择第一辆车
                        this.isAllowReplaceCar();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);

                });
            return;
        },
        async init(startTime, endTime) {
            this.initLoading = true;
            // 获取生产线（右上角）
            await this.mixstationline();
            // 获取生产线下的车辆
            await this.mixstationlineCar();
            // 获取列表请求
            if (this.timeSlot !== []) {
                this.shipments(this.timeSlot[0], this.timeSlot[1]);
            } else {
                this.shipments(startTime, endTime);
            }
            // this.$refs.blockcolor.initBlockCzColor();
            // 获取生产线统计信息
            await this.statistics();
            // 生产任务统计信息
            this.getDefaultTimeLoad();
            // 获取是否可以加减量
            this.getStationConfig();
        },
        // tabs切换
        handleClick(tab) {
            console.log(tab, 'tabbb');
            this.initData();
            // 是否允许更换车辆，不选择第一辆车
            this.isAllowReplaceCar();
            this.topClickInd = tab.index;
            this.activeName = this.siteData[tab.index].mixstation_code;
            this.init();
            this.taskItem = '';
            this.taskNumber = '';
            this.isSelect = 0;
            this.radioTaskChenked = '';
            // 获取打电话api地址
            this.getCallPhoneApi();
        },
        // 获取默认时间范围
        async getDefaultTime() {
            return this.$axios
                .get('/interfaceApi/production/producttask/filtertime');
        },
        getDefaultTimeLoad() {
            this.$axios
                .get('/interfaceApi/production/producttask/load')
                .then(res => {
                    this.dateSettingForm = res || {
                        filter_prev_day: 0,
                        filter_after_day: 0,
                        filter_time: '00:00',
                    };
                    this.initLoading = false;
                })
                .catch(error => {
                    this.initLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 打开协同数据
        collaborativeData() {
            const _that = this;
            this.$toast({
                text: '协同数据',
                title: true,
                type: 'eject',
                width: '13.4rem',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'xtsj',
                        QueryString: 'order_number=' + _that.radioData.order_number,
                    },
                },
            });
        },
        // 选择车辆更换确认
        replaceVehicle(data) {
            this.selectedVehicles = data;
            this.changeLicenseNumber = data.plate_small_number;
        },
        // 更换车辆发送
        confirmReplaceVehicle() {
            const data = {
                pcb_number: this.originalInfo.pcb_number,
                plate_number: this.selectedVehicles.plate_number,
                filled_volume: this.formulaline.filled_volume,
                transfer_volume: this.formulaline.transfer_volume,
                transfer_sj_volume: this.formulaline.transfer_sj_volume,
            };
            this.$axios
                .post(`/interfaceApi/production/productpcb/replace/vehicle/${sessionStorage.getItem('clientID')}`, data)
                .then(() => {
                    this.$message.success('更换车辆成功!');
                    this.dialogFormVisible3 = false;
                    this.shipments(this.timeSlot[0], this.timeSlot[1]);
                    this.mixstationlineCar();
                })
                .catch(error => {
                    this.btnLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 拉水
        async pullWater() {
            // 获取等待车辆
            await this.getwaitvehicle();
            if (this.radioTaskChenked) {
                this.$toast({
                    title: true,
                    text: '拉水业务',
                    type: 'eject',
                    width: '9rem',
                    height: '5rem',
                    t_url: 'productionManagement/productionDelivery/pullWaterForm/index',
                    extr: {
                        // 选中的任务单号
                        radioTaskChenked: this.radioTaskChenked,
                        // 待选车辆
                        vehicleData: this.vehicleList,
                        sureCallback: this.deselectCarCallback,
                        closeCallback: this.deselectCarCallback,
                    },
                });
            } else {
                this.$message.error('请先选择任务单');
            }
        },

        // 取消选择车辆回调
        deselectCarCallback() {
            const _that = this;
            // 重新绑定回车键查询
            document.onkeydown = function () {
                const key = window.event.keyCode;
                if (key === 13) {
                    _that.searchList();
                }
            };
        },
        computeTypeChange() {
            this.computeAmountInfo.concrete_volume = 0;
            this.computeAmountInfo.mortar_volume = 0;
        },

        // 设置本机号码
        setClientPhone() {
            localStorage.setItem('clientPhone', this.clientPhone);
        },
        // 打开打电话弹窗
        handleOpenCallPhone(row) {
            if (this.callPhoneApi) {
                this.curDriverPhone = row.xclxr_phone;
                this.dialogCallPhone = true;
            }
        },
        // 执行拨打电话
        handleCallPhone() {
            this.callPhoneLoading = true;
            // const timestamp = new Date().getTime();
            const timestamp = 100000;
            const nonce = '2021';
            const signature = md5('3086440' + nonce + timestamp);
            const xml = '<?xml version="1.0" encoding="utf-8" ?>'
                + `<Transfer attribute="Connect"><ext id="${this.clientPhone}"/><outer to="0${this.curDriverPhone}"/></Transfer>`
                + `<Auth><Timestamp>${timestamp}</Timestamp><nonce>${nonce}</nonce><Signature>${signature}</Signature></Auth>`;
            const xmlHttp = new XMLHttpRequest();
            xmlHttp.open('post', this.callPhoneApi);
            xmlHttp.send(xml);
            xmlHttp.onreadystatechange = () => {
                this.callPhoneLoading = false;
                if (xmlHttp.status === 200) {
                    // this.dialogCallPhone = false;
                    // this.$message.success('拨打成功~');
                } else {
                    // this.$message.warning('拨打失败:' + xmlHttp.responseText);
                }
            };
        },
        // 根据当前场站获取 打电话api地址
        getCallPhoneApi() {
            this.$axios
                .get(`/interfaceApi/baseinfo/stationmanger/get_station_telephony_support_address/${this.activeName}`)
                .then(data => {
                    this.callPhoneApi = data || '';
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 一车多单
        multipleOrderOneCar() {
            this.$toast({
                title: true,
                text: '一车多单',
                type: 'eject',
                width: '80%',
                height: '90%',
                t_url: 'productionManagement/productionDelivery/multipleOrderOneCar/index',
                extr: {
                    wait_car_list: this.showDialogWaitCarList,
                    WaitCarActive: this.plate_small_number,
                    filterParams: {
                        station: this.activeName,
                        start_time: this.timeSlot[0],
                        end_time: this.timeSlot[1],
                        search: this.sousuokuang,
                    },
                    success: () => {
                        this.detailsDelivery();
                        this.mixstationlineCar();
                        this.detailsDeliveryCount();
                    },
                },
            });
        },

        // 根据当前场站获取 是否可以加减量
        getStationConfig() {
            this.$axios
                .get(`/interfaceApi/production/profile/constant/fkey/enable_add_decrement/fability/${this.activeName}`)
                .then(data => {
                    // eslint-disable-next-line eqeqeq
                    this.canAdjustVolume = data == 1;
                });
        },

    },
    beforeDestroy() {
        // 清除事件总线eventBus， 不手动清除，它是一直会存在的，就会反复进入
        eventBus.$off('UpdateVehicleMessage');
        eventBus.$off('TankCarFrozenMessage');
        eventBus.$off('TankCarStatusChangeMessage');
        eventBus.$off('ReLoadProductTask');
        eventBus.$off('DriverChangeMessage');
        eventBus.$off('RefreshVehicle');
        eventBus.$emit('signalrInvoke', 'RemoveFromGroup', this.groupName);
    },
    async mounted() {
        const _that = this;
        document.addEventListener('click', () => {
            this.clickCarInfo = false;
        });
        try {
            const defaultTime = await this.getDefaultTime();
            if (defaultTime) {
                this.timeSlot = [defaultTime.start_time, defaultTime.end_time];
            } else {
                this.timeSlot.push(this.getDateStr(-6), this.getDateStr(0));
            }
        } catch (err) {
            console.log(err);
        }

        this.deliverCar = [];
        await this.gitSiteData();
        await this.init();
        this.getCallPhoneApi();
        // 添加事件总线eventBus
        eventBus.$on('UpdateVehicleMessage', data => {
            if (data.tenant_id === this.$takeTokenParameters('TenantId') && data.mixstation_code === _that.activeName) {
                _that.detailsDelivery();
                const reday_car_list = this.formatMixstationlineCarStruct(data.reday_car_list);
                _that.mixstationlineCarData = {
                    product_car_list: data.product_car_list,
                    reday_car_list: reday_car_list,
                };
                const row = data.wait_car_list.filter(item => item.plate_small_number === _that.plate_small_number);
                if (
                    row.length < 1
                    && this.plate_small_number !== ''
                    && data.client_id !== sessionStorage.getItem('clientID')
                    && this.radioData2.shch !== this.plate_small_number
                ) {
                    _that.$notify({
                        type: 'warning',
                        title: '提醒',
                        message: `车辆 ${this.plate_small_number} 已派出，请重新选择车辆`,
                        position: 'bottom-right',
                        duration: 2000,
                        onClose: () => {
                            _that.mixstationlineCarData.wait_car_list = data.wait_car_list;
                            _that.filteringVehicles();
                        },
                    });
                } else {
                    _that.mixstationlineCarData.wait_car_list = data.wait_car_list;
                    _that.filteringVehicles();
                }
            }
        });

        // 1冻结 2 预警
        // pcb_number  排车表编号
        // phone   司机手机号
        eventBus.$on('TankCarFrozenMessage', data => {
            const Data = JSON.parse(data);
            if (Data.tenant_id === this.$takeTokenParameters('TenantId') && Data.mixstation_code === _that.activeName) {
                let Index = null;
                let Row = [];
                let CellIndex = null;
                if (this.fhmxList.length > 0) {
                    Row = this.vehicleList[Data.line_code];
                    Index = _.findIndex(this.fhmxList, function (o) { return o.pcb_number === Data.pcb_number; });
                    CellIndex = _.findIndex(Row, function (o) { return o.pcb_number === Data.pcb_number; });
                    if (Data.frozen === 2) {
                        this.fhmxList[Index].status = 6;
                        this.vehicleList[Data.line_code][CellIndex].status = 6;
                    } else if (Data.frozen === 1) {
                        this.fhmxList[Index].status = 7;
                        this.vehicleList[Data.line_code][CellIndex].status = 7;
                    }
                }
            }
        });

        // 等待车辆调整设置进出场
        eventBus.$on('TankCarStatusChangeMessage', data => {
            const Data = JSON.parse(data);
            if (Data.tenant_id === this.$takeTokenParameters('TenantId') && Data.mixstation_code === _that.activeName && Data.car_status === 1) {
                this.getwaitvehicle();
            }
        });

        // 刷新生产发货列表
        eventBus.$on('ReLoadProductTask', data => {
            if (data.tenant_id === this.$takeTokenParameters('TenantId')) {
                this.shipments(this.timeSlot[0], this.timeSlot[1]);
            }
        });

        // 司机变化消息
        eventBus.$on('DriverChangeMessage', data => {
            const Data = JSON.parse(data);
            if (Data.tenant_id === this.$takeTokenParameters('TenantId') && Data.mixstation_code === _that.activeName) {
                this.getwaitvehicle();
            }
        });

        // 接受，添加生产发货、删除、换线
        eventBus.$on('RefreshVehicle', data => {
            const Data = JSON.parse(data);
            if (Data.tenant_id === this.$takeTokenParameters('TenantId')
            && Data.mixstation_code === _that.activeName
            && Data.client_id !== sessionStorage.getItem('clientID')) {
                this.mixstationlineCar();
            }
        });

        setTimeout(() => {
            const tenant_id = this.$takeTokenParameters('TenantId');
            this.groupName = tenant_id + '-PRODUCTION_DELIVERY';
            eventBus.$emit('signalrInvoke', 'AddToGroup', this.groupName);
        }, 1000);
    },
    filters: {
        formatMinutes(value) {
            const date = new Date(value);
            const year = date.getFullYear();
            const month = date.getMonth();
            const data = date.getDate();
            const hour = date.getHours();
            const minutes = date.getMinutes();
            return year + '-' + (month > 10 ? month : '0' + month) + '-'
            + (data > 10 ? data : '0' + data) + ' ' + (hour > 10 ? hour : '0' + hour) + ':'
            + (minutes > 10 ? minutes : '0' + minutes);
        },
        formatStatus(value) {
            switch (value) {
                case 1:
                    return '未接单';
                case 2:
                    return '作废';
                case 3:
                    return '完成';
                case 4:
                    return '退砼';
                case 5:
                    return '已接单';
                case 6:
                    return '预警';
                case 7:
                    return '冻结';
                case 8:
                    return '打料开始';
                case 9:
                    return '打料完成';
                default:
                    return ' ';
            }
        },
    },
};
</script>
<style lang="stylus" scope>
// 头部区域
.car-state-title
    display flex
    padding-left 0.14rem
    border-radius: .04rem;
    width 100%
    overflow-x:auto
.bg-purple-dark
    background: #99a9bf;
.bg-purple
    background: #EEEEEE;
    color #000
.bg-purple-light
    background: #e5e9f2;
.grid-content
    position relative
    height: .38rem
    width 1.28rem
    border-radius: .04rem
    margin-right .14rem
    font-size .18rem
    display flex
    justify-content center
    align-items center
    margin-top .16rem
    margin-bottom .14rem
    cursor pointer
    padding 0 5px
    .grid-title
        width 100%
        text-align center
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
.row-bg
    padding: .1rem 0;
    background-color: #f9fafc
.contene_active
    margin-top: .06rem;
    height: .48rem;
.select-style-bottom
    width 100%
    height .06rem
    position absolute
    left 0
    bottom 0
    display flex
    justify-content center
    .bottom_div
        width: 60%;
        height: 100%;
        background-color: #CDE3FF;
        -webkit-transform: perspective(0.1em) rotateX(5deg);
.select-style
    position absolute
    top 0
    lef 0
    width 100%
    height 100%
    border-radius .04rem
    display flex
    align-items center
    justify-content center
    background #006EFF !important
    color #fff !important
    span
        position absolute
        color white
        bottom 0
        right 0
        font-size .2rem
        z-index 2
    .border-right
        position absolute
        width: 0;
        height: 0;
        border-bottom: .3rem solid #D80016;
        border-left: .3rem solid transparent;
        bottom 0
        right 0

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}
.link
    color: blue;
    text-decoration: underline;
    cursor: pointer;
.el-button
    height 0.36rem
    padding 0 0.2rem
.el-popover
    .el-form-item__content,.el-input__inner
        width 1.34rem !important
    .task-message_wrapper
        p
            line-height 0.3rem
            font-size 0.14rem
            span
                font-weight 600
.el-drawer
    overflow-y auto !important
.dialog-form-visible_adjust
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0,0,0, 0.4)
    display flex
    justify-content center
    align-items center
    z-index 999
    .adjust-wrapper
        width 14.4rem
        height auto
        background #FFF
        display flex
        flex-direction column
        align-items center
        .adjust-wrapper_header
            width 100%
            height 0.6rem
            background url(../../../assets/images/popUpForm/banner.png)
            line-height 0.6rem
            color #ffffff
            display flex
            position relative
            h1
                text-align center
                flex 1
                font-size 0.2rem
            span
                padding 0 0.2rem
                font-size 0.16rem
                cursor pointer
                position absolute
                top 0
                right 0
        .adjust-wrapper_section
            width 100%
            flex 1
            overflow hidden
            .main-body_content
                padding 0.15rem
                .grid-content
                    display flex
                .top-wrapper_message
                    width 100%
                    // border 0.01rem solid rgba(215,215,215,1)
                    position relative
                    .message-wrapper_mask
                        position absolute
                        width 100%
                        height 100%
                        background #ffffff
                        left 0
                        top 0
                        z-index 100
                        opacity 0.5
                    h2
                        height 0.4rem
                        line-height 0.4rem
                        font-weight 900
                        text-align center
                        color #022782
                        font-size 0.18rem
                        background rgba(237,240,245,1)
                        margin-top 0
                    .message_content
                        padding 0.1rem 0.2rem
                        .grid-content
                            line-height 0.4rem
                            p
                                color #333333
                                font-size 0.16rem
                                display flex
                                i
                                    color #1577D2
                                    padding-right 0.1rem
                                    font-weight 600
                    .discard-wrapper_bg
                        position absolute
                        left 0.2rem
                        top 0
                    &:last-child
                        h2
                            color #823802
                            background rgba(245,242,237,1)
                        .no-message_content
                            padding 0.2rem 0.2rem 0.1rem 0.2rem
                            position relative
                            &::after
                                content '不可更改'
                                width 0.8rem
                                height 0.2rem
                                position absolute
                                left 0
                                top 0
                                background rgba(255,77,79,0.1)
                                border-radius 0 0 0.2rem 0
                                color #FF4D4F
                                font-size 0.12rem
                                text-align center
                                line-hieght 0.2rem
                            p
                                display flex
                                padding-bottom 0.1rem
                                span
                                    color #777777
                                    font-size 0.16rem
                                    width 1.2rem
                                    text-align right
                                    display inline-block
                                    margin-right 0.15rem
                                    line-height 0.36rem
                                .el-input
                                    width 2rem
                                    height 0.36rem
                        .yes-message_content
                            padding 0.2rem 0.2rem 0.2rem 0.2rem
                            position relative
                            border-top 0.01rem dashed #D7D7D7
                            &::after
                                content '可更改'
                                width 0.8rem
                                height 0.2rem
                                position absolute
                                left 0
                                top 0
                                background rgba(94,200,42,0.1)
                                border-radius 0 0 0.2rem 0
                                color #5EC82A
                                font-size 0.12rem
                                text-align center
                                line-hieght 0.2rem
                            p
                                span
                                    font-size 0.16rem
                                .el-input
                                    width 2.2rem
                                    height 0.36rem
                                .el-radio-group
                                    .el-radio
                                        margin-top 0.08rem
                .nav-warpper_select
                    width 100%
                    display flex
                    padding 0.2rem 0
                    .el-radio-group
                        margin-right 0.4rem
                        .el-radio
                            margin-top 0.08rem
                    .el-button
                        width 0.82rem
                        background rgba(21,119,210,1)
                        border-radius 0.02rem
                        display block
                        margin-right 0.4rem
                        line-height 0.36rem
                        color #ffffff
                        font-size 0.14rem
                        border-radius: 2px;
                    .disabled_adjust
                        background #ccc
                    p
                        margin-right 0.4rem
                        .el-input
                            width 2rem
                            height 0.36rem
        .adjust-wrapper_footer
            width 100%
            height 0.8rem
            display flex
            justify-content center
            .btn
                width 1.84rem
                height 0.4rem
                border 0.02rem solid rgba(21,119,210,1)
                color #1577D2
                background #fff
                display inline-block
                cursor pointer
                margin 0.1rem 0.2rem
            .confirm_btn
                color #ffffff
                border none
                background rgba(21,119,210,1)
                cursor default
            .send-btn
                background #1577D2
                cursor pointer
.dialog-form-visible
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0,0,0, 0.4)
    display flex
    justify-content center
    align-items center
    z-index 1000
    .dialog-form-visible-center
        width 12rem
        max-height 8rem
        background #FFF
        display flex
        flex-direction column
        align-items center
        .dialog-form-visible-header
            width 100%
            height 0.6rem
            background url(../../../assets/images/popUpForm/banner.png)
            position relative
            h1
                text-align center
                color #fff
                height 0.6rem
                font-size 0.18rem
                line-height 0.6rem
            span
                position absolute
                top 0rem
                right 0rem
                color #fff
                cursor pointer;
                .el-icon-close
                    width 0.6rem
                    line-height 0.6rem
                    text-align center
                    font-size 0.2rem
        .table-content
            width 100%
            padding 0.1rem
            flex 1
            .search-wrapper
                display flex
                justify-content space-between
                padding 0.1rem 0
                .el-input-group__append
                    background #1577d2
                    width 0.6rem
                    border none
                    padding 0
                    cursor pointer
                    .search-btn
                        border none
                        background #1577d2
                        width 0.6rem
                        cursor pointer
                        i
                            color #ffffff
            .select_btn
                background #2978ff
                color #fff
                border none
                width 1rem
                height 0.4rem
                border-radius: 0.06rem
                cursor pointer
            .el-table
                border 1px solid #ccc
                .el-table__header-wrapper
                    .el-table__header
                        .has-gutter
                            tr
                                background #edf0f5 !important
                .el-radio__label
                    display none
        .footer-btn
            height 0.44rem
            display flex
            justify-content center
            margin 0.2rem 0
            .btn
                width 1.84rem
                height 0.44rem
                border 0.02rem solid rgba(21,119,210,1)
                color #1577D2
                background #fff
                // margin 0 auto
                display inline-block
                cursor pointer
                margin 0 0.2rem
            .confirm_btn
                background #1577D2
                color #ffffff
                border none
        .tuiT_btn
            background rgba(45,68,125,1)
            color #fff
            border none
            width 1rem
            height 0.4rem
            border-radius: 0.06rem
            margin 0.1rem 0.2rem 0 0.2rem
            cursor pointer
        .form-content
            width 100%
            padding .2rem
            display flex
            flex-wrap wrap
            div
                width 50%
                display: flex;
                height: .5rem;
                align-items: center;
                border-left: 1px solid #ccc;
                border-bottom 1px solid #ccc
                &:nth-child(1){
                    border-top: 1px solid #ccc;
                }
                &:nth-child(2){
                    border-top: 1px solid #ccc;
                }
                p
                    display: flex;
                    align-items: center;
                    padding-left: .2rem;
                    height 100%
                p:first-child
                    width: 2rem;
                    text-align: center;
                    border-right 1px solid #ccc
            div:nth-child(2n)
                border-right: 1px solid #ccc;

.error-color
    color red !important
.no-order
    color #595959 !important
.yes-order
    color #1C9D60 !important
.warning-order
    color #ED8100 !important
.frozen-order
    color #FF0500 !important
.production-icon
    position absolute
    bottom 2.7rem
    width .5rem
    height .8rem
    display flex
    justify-content center
    align-items center
    box-shadow: .02rem .01rem .06rem 0px rgba(85,136,241,0.6);
    p
        width .3rem
        font-size .18rem
        color #1577D2
        font-weight 800
        margin 0 .05rem 0 0.1rem;
.production-icon-l
    left: .2rem
    border 1px solid #ccc
    border-left none
    border-radius 0 .2rem .2rem 0
    .triangle-left
        display block
        width: 0;
        height: 0;
        border-top: .08rem solid transparent;
        border-left: .14rem solid #1577D2;
        border-bottom: .08rem solid transparent;
        margin-right .1rem;
.production-icon-r
    right .2rem
    border 1px solid #ccc
    border-right none
    border-radius .2rem 0 0 .2rem
    z-index 2
    .triangle-right
        display block
        width: 0;
        height: 0;
        border-top: .08rem solid transparent;
        border-right: .14rem solid #1577D2;
        border-bottom: .08rem solid transparent;
        margin-left .05rem
.productionShipment
    background #fff !important
// 旧
.productionShipment .el-icon-arrow-left
    font-size: 0.3rem;
    display: block;
    position: relative;
    left: .02rem;
    top .18rem
.productionShipment .el-icon-arrow-right
    font-size: 0.3rem;
    display: block;
    position: relative;
    left: .06rem;
    top .18rem
    color red !important
.information-car
    width 2.2rem
    height 1.9rem
    border:1px solid rgba(215, 215, 215, 1);
    box-shadow:0px 2px 8px 0px rgba(0, 0, 0, 0.2);
    font-size .12rem
    z-index 99
    background #FFFFFF
    position fixed
    h5
        width 100%
        height .24rem
        background #5588F1
        text-align center
        line-height .24rem
        color #fff
    p
        line-height .3rem
        display flex
        color #333333
        span
            display inlien-block
            color #B6BDCB
            margin 0 .1rem

.blue-color
    color #5588F1 !important
    cursor pointer;
.red-color
    color #EB656F !important
    cursor pointer;
.green-color
    color #21BDCC !important
    cursor pointer;
.productionShipment
    height 100%
    display -webkit-flex
    display -ms-flex
    display flex
    flex-direction column
    overflow-y auto !important
    overflow-x hidden
    background #e7e8eb
    .title
        height 0.6rem
        background #fff
        margin-bottom 0.2rem
        .tabs
            max-width 56%
            height 0.6rem
            .el-tabs__header
                margin-bottom 0
                .el-tabs__nav-wrap
                    padding 0 0.2rem
                    &:after
                        display none
                    .el-tabs__nav
                        .el-tabs__item
                            height 0.6rem
                            line-height 0.6rem
                            &:hover, span, strong
                                color #D6000F
                            &.is-active
                                span, strong
                                    color #D6000F
                            span
                                padding 0 0.2rem
                                font-size 0.16rem
                                font-weight 600
                                color #333
                                strong
                                    font-weight 400
                                    color #979EA7
            .el-tabs__active-bar
                background-color #D6000F
        .operationArea
            height 100%
            display flex
            align-items center
            >div
                margin-right 0.14rem
            .timeSlot
                .el-range-editor--small
                    .el-range-separator,
                    .el-input__icon
                        line-height 24px
                .el-date-editor
                    height 0.36rem
    .content_head
        display flex
        padding .1rem
        margin 0 .1rem .1rem .1rem
        background #fff
        box-shadow 1px 0px 9px -3px #707070;
        .el-icon-arrow-up:before
            content: "\e78f" !important;
        .el-input__suffix
            background #EFF4FF !important
            right 1px !important
        .el-input__suffix
            height 91% !important
            top 1px !important
        .head_left
            width calc(100% - 7.4rem)
            margin-right .1rem
            .Summary
                height 0.7rem
                display flex
                justify-content space-between
                li
                    width 24%
                    margin-right 0.06rem
                    border 1px solid #D7D7D7
                    height 0.5rem
                    line-height 0.48rem
                    .title_icon
                        display inline-block
                        width .4rem
                        height .4rem
                        margin .05rem
                    .title_icon1
                        background url('../images/summary1.png') no-repeat
                        background-size 100% 100%
                    .title_icon2
                        background url('../images/summary2.png') no-repeat
                        background-size 100% 100%
                    .title_icon3
                        background url('../images/summary3.png') no-repeat
                        background-size 100% 100%
                    .title_icon4
                        background url('../images/summary4.png') no-repeat
                        background-size 100% 100%
                    i,span
                        float left
                    i
                        height 0.26rem
                        width 0.26rem
                        margin 0.11rem 0 0.11rem 0.08rem
                        position relative
                        border-radius 50%
                        &:before
                            position absolute
                            top 50%
                            left 50%
                            transform translate(-50%, -50%)
                            font-size 0.16rem
                            color #fff
                    span
                        font-size 0.14rem
                        color #333333
                        margin-left 0.08rem
                    strong
                        display inline-block
                        max-width: 0.73rem;
                        float right
                        font-weight 400
                        margin-right 0.06rem
                        font-size 0.2rem
                        overflow hidden
                        text-overflow ellipsis
                        white-space nowrap
                    &:nth-of-type(1)
                        i
                            background #006EFF
                        strong
                            color #006EFF
                    &:nth-of-type(2)
                        i
                            background #FC844F
                        strong
                            color #FC844F
                    &:nth-of-type(3)
                        i
                            background #3FA271
                        strong
                            color #3FA271
                    &:nth-of-type(4)
                        i
                            background #FFB506
                        strong
                            color #FFB506
                    &:nth-of-type(5)
                        i
                            background #E6A23C
                        strong
                            color #E6A23C
                        margin-right 0
            .productionLine
                height 1.65rem
                padding 0.06rem
                table
                    width 100%
                    height 100%
                    table-layout fixed
                    border-collapse collapse
                    td,th
                        border 1px solid #DEDFE0
                        text-align center
                        font-size 0.16rem
                        color #022782
                        height .408rem
                        // width 1.5rem
                    th
                        padding .02rem
                        width 0.8rem
                        background #ffffff
                        color #333
                        div
                            background #F0F0F0
                            width 100%
                            height 100%
                            display flex
                            align-items center
                            justify-content center
                            &.await
                                background #FDF5E3 !important
                                color #344147
                    td
                        background #fff
                        padding .02rem
                        div
                            display flex
                            align-items center
                            justify-content center
                            width 100%
                            height 100%
                            &.Line1
                                background #F3F6FF !important
                                color #556FC6 !important
                            &.Line2
                                background #F1F9ED !important
                                color #81BA68 !important
                            &.Line3
                                background #FDF5E3 !important
                                color #FBC858 !important
                            &.Line4
                                background #EFFAFE !important
                                color #73C0DE !important
                            &.Line5
                                background #EDF5F1 !important
                                color #3BA271 !important
                            &.Line6
                                background #F7EDE9 !important
                                color #FB8450 !important
                            &.Line7
                                background #F4EFF6 !important
                                color #9B60B4 !important
                        &.td1
                            color #556FC6
                        &.td2
                            color #81BA68
                        &.td3
                            color #FBC858
                        &.td4
                            color #73C0DE
                        &.td5
                            color #3BA271
                        &.td6
                            color #FB8450
                        &.td7
                            color #9B60B4
                        &.await-car-list-wrapper
                            position relative
                            text-align left
                            .await-car-list-main
                                white-space nowrap
                                text-overflow ellipsis
                                overflow hidden
                                word-break break-all
                            .await-car-list-box
                                width 100%
                                position absolute
                                left 0
                                top 0
                                background #fff
                                white-space pre-wrap
                                padding-top 0.06rem
                        strong
                            font-weight 800
                            margin 0 0.02rem
                            font-size 0.14rem
                        .await-car-wrapper
                            display flex
                            color #022782
                            .open-logo
                                width 0.2rem
                                cursor pointer
                                strong
                                    color #02278
                            .await-list
                                flex 1
                                strong
                                    color #02278
                        .orange-flag
                            color #ED8100
                        .tui_flag
                            background rgba(255,241,0,1)
                            border-radius 0.1rem
                            padding 0 0.1rem
                            height 0.2rem
                            line-height 0.2rem
                            position relative
                            &:hover
                                &::after
                                    content '退砼'
                                    width 0.4rem
                                    height 0.3rem
                                    background url(/assets/images/productionManagement/popover.png) no-repeat
                                    background-size 100% 100%
                                    position absolute
                                    left 0.2rem
                                    top -0.28rem
                                    color #fff
                            .tui_text
                                width 0.2rem
                                height 0.2rem
                                border-radius 50%
                                border 1px solid #022782
                                display inline-block
                                margin-top 0.02rem
                                text-align center
        .head_right
            .el-tabs__nav-scroll
                background-color #EFF4FF
            width 7.4rem
            .deliveryMode
                height 2.5rem
                margin-bottom 0
                padding 0
                position relative
                .el-input-group__append
                    background #EFF4FF !important
                .tcfh_title_icon_box
                    padding .05rem 0
                    display flex
                    .tcfh_title_icon
                        display inline-block
                        width .35rem
                        height .35rem
                        margin .03rem
                        background url('../images/tcfh.png') no-repeat
                        background-size 75% 75%
                .operation-btn
                    width 50%
                    position absolute
                    z-index 5
                    right 0
                    background #eff4ff
                    display flex
                    justify-content flex-end
                    .btn1
                        width 1rem
                        height .32rem
                        display flex
                        align-items center
                        justify-content center
                    .btn2
                        width .32rem
                        height .32rem
                        display flex
                        align-items center
                        justify-content center
                    p
                        padding 0 .1rem
                        font-size .16rem
                        line-height .4rem
                        cursor:pointer
                    .removeCar{
                        color #D60110
                    }
                .el-tabs
                    border none
                    box-shadow none
                    height 100%
                    .el-tabs__nav
                        height 0.36rem
                        .el-tabs__item
                            height 0.3rem
                            line-height 0.3rem
                            margin-top 0.02rem
                            margin-left -.18rem
                            font-weight 500
                            &.is-active
                                border none
                                color #000
                                background-color #EFF4FF !important
                    .el-tabs__content
                        padding 0.1rem 0
                        height calc(100% - 0.36rem)
                        .el-tab-pane
                            height 100%
                            table
                                width 100%
                                height 100%
                                border-collapse collapse
                                border 1px solid #D7D7D7
                                th
                                    text-align right
                                    font-size 0.14rem
                                    color #333333
                                    font-weight 400
                                    padding .02rem
                                    background #fff
                                    >div
                                        width 100%
                                        height 100%
                                        background #F8F8F8
                                        display flex
                                        justify-content end
                                        align-items center
                                td
                                    .el-select,.el-input-number,.el-input
                                        width 1.55rem
                                    .el-input__inner
                                        padding-right 0
                                    .el-input-group__append
                                        padding 0 0.06rem
    .content
        display flex
        height calc(100% - 3.6rem)
        >div
            height 8.4rem
        .content_l
            width calc(100% - 7.5rem)
            height 100%
            background #fff
            padding  0 .2rem .2rem .2rem
            position relative
            &.hideListWidth
                width 100%
                .expansionAreaBtn
                    right 0
                    transform rotate(180deg)
                    border-radius 0 4px 4px 0 !important
            .block_color_open
                left 0;
            .block_color_up
                left -105%;
            .left_btn
                width 0.52rem
                height 0.8rem
                background url(../images/production.png) no-repeat
                background-size 100% 100%
                position absolute
                left -0.02rem
                bottom 3.2rem
                z-index 99
                cursor pointer
            .right_btn
                width 0.52rem
                height 0.8rem
                background url(../images/back.png) no-repeat
                background-size 100% 100%
                position absolute
                right -0.02rem
                bottom 3.2rem
                z-index 99
                cursor pointer
            &:after
                content ''
                position absolute
                top 0.9rem
                left 0
                width 100%
                border-bottom 1px solid #F0F0F0
            .projectTable
                height calc(100%)
                .tableSearch
                    height 0.66rem
                    padding 0.1rem 0 0.1rem
                    display flex
                    justify-content space-between
                    .tableSearchBtns
                        .el-button
                            height 0.36rem
                            width 0.8rem
                            margin-left 0
                            margin-right 0.05rem
                            border-radius 2px
                            font-size 0.14rem
                            cursor pointer;
                            padding 0.1rem 0
                            text-align center
                            background #F1FBFF
                            color #73C0DE
                            border-color #73C0DE
                        .suspend_btn
                            background #FFF3F3 !important
                            color #F06764 !important
                            border-color #F06764 !important
                    .tableSearchFilter
                        display flex
                        .el-select
                            width 1.2rem
                        .sousuoicon
                            width 1.7rem
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-left 0.05rem
                            margin-top -.1rem
                        .cacheTableHeaderSet
                            margin-left 0.05rem
                        .el-input
                            position relative
                            .el-input__inner
                                height 0.36rem
                                border 1px solid #D7D7D7
                                border-radius 2px
                                font-size 0.14rem
                                padding-left 0.1rem
                            &:before
                                position absolute
                                right 0.1rem
                                top 50%
                                transform translateY(-50%)
                                font-size 0.16rem
                                color #969EA7
                    .reset-set-out
                        background none
                        color #3dc72d
                        border 1px solid #3dc72d
                .table-bg2
                        padding-top .07rem
                        margin-top -.05rem
                        height calc(100% - 0.6rem) !important
                .table-bg
                    border 0.01rem solid #D7D7D7
                    height calc(100% - 0.66rem)
                    .color
                        display block
                        height 0.2rem
                    .el-table
                        z-index 1 !important
                        margin-top -.06rem
                        .el-table__header
                            th
                                border-right 0.01rem solid #ccc
                        .el-radio__label
                            display none
            .expansionAreaBtn
                top 50%
                border none
                width 0.15rem
                height 0.5rem
                cursor pointer
                right .04rem
                text-align center
                position absolute
                background #adb9c8
                margin-top -0.3rem
                line-height 0.5rem
                border-radius 0 4px 4px 0
                display flex
                justify-content center !important
                &:hover
                    background #1577d2
                i
                    color #fff
        .contetn_r
            width 7.4rem
            height 100%
            position relative
            >div
                background #fff
                margin-bottom 0.2rem
                padding-bottom 0.1rem
            .detailsOfDelivery
                height calc(100%)
                padding-top 0
                flex-direction: column;
                overflow hidden
                .top-block
                    display flex
                    justify-content space-between
                    margin-bottom: 0.1rem;
                    font-size .18rem
                    align-items center
                .fahuomingxi
                    display flex
                    h4
                        // padding-left .1rem
                        // border-left: 0.06rem solid #1577d2
                        font-weight 500
                        font-size 0.18rem
                        line-height 0.2rem
                        height 0.2rem
                        margin-top 0.04rem
                        margin-right 0.5rem
                        display flex
                        .fhmx_title_icon
                            display inline-block
                            width .25rem
                            height .25rem
                            margin 0 .05rem .05rem .05rem
                            background url('../images/fhmx.png') no-repeat
                            background-size 100% 100%
                .four-top
                    display flex
                    height: .5rem;
                    justify-content center
                    align-items center
                    // margin-top .1rem
                    .shu
                        width 1px
                        height .2rem
                        background #D7D7D7
                        margin: 0 .2rem
                    .top-four-block
                        text-align center
                        font-size 0.12rem
                        strong
                            font-weight 800
                            margin 0 0.1rem
                            font-size 0.16rem
                        &:nth-of-type(1)
                            strong
                                color #FC8452
                        &:nth-of-type(2)
                            strong
                                color #3BA272
                        &:nth-of-type(3)
                            strong
                                color #006EFF
                        &:nth-of-type(4)
                            margin-right 0
                            strong
                                color #FBC858
                .table-bg
                    height calc(100% - 0.6rem)
                    .el-table
                        border 0.01rem solid #D7D7D7
                        .check_row
                            background #FFF100
                            .cell
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: unset;
                        .check_cell
                            position relative
                            overflow hidden
                            &::after
                                content '退砼'
                                width 0.5rem
                                height 0.5rem
                                background red
                                position absolute
                                right -0.24rem
                                top -0.24rem
                                color #fff
                                font-size 0.14rem
                                text-align center
                                padding-top 0.3rem
                                transform rotate(45deg)
                                -webkit-transform rotate(45deg)
                                -moz-transform rotate(45deg)
                        .check_cell_th
                            position relative
                            overflow hidden
                            &::after
                                content '退货'
                                width 0.5rem
                                height 0.5rem
                                background red
                                position absolute
                                right -0.24rem
                                top -0.24rem
                                color #fff
                                font-size 0.14rem
                                text-align center
                                padding-top 0.3rem
                                transform rotate(45deg)
                                -webkit-transform rotate(45deg)
                                -moz-transform rotate(45deg)
                        .deletingStyle
                            position relative
                            overflow hidden
                            background #feeeee
                            td
                                color #ee3737
                            &::after
                                content ''
                                width 0.34rem
                                height 0.34rem
                                position absolute
                                font-size 0.12rem
                                right 0
                                background url(../images/gif.gif) no-repeat
                .el-table__fixed-right
                    .abnormalStyle
                        position relative
                        overflow hidden
                        &::after
                            content ''
                            width 0.2rem
                            height 0.2rem
                            position absolute
                            font-size 0.12rem
                            right 0
                            margin-top 0.08rem
                            margin-right 0.02rem
                            background url(../images/abnormal.png) no-repeat
            .deliveryMode
                height 2.5rem
                margin-bottom 0
                padding 0
                position relative
                .operation-btn
                    width 50%
                    position absolute
                    z-index 5
                    right 0
                    display flex
                    justify-content flex-end
                    p
                        padding 0 .1rem
                        font-size .16rem
                        line-height .4rem
                        cursor:pointer
                    .removeCar{
                        color #D60110
                    }
                .el-tabs
                    border none
                    box-shadow none
                    height 100%
                    .el-tabs__nav
                        padding 0 0.2rem
                        height 0.36rem
                        .el-tabs__item
                            height 0.3rem
                            line-height 0.3rem
                            margin-top 0.06rem
                            &.is-active
                                border-top-color #DCDFE6
                    .el-tabs__content
                        padding 0.1rem
                        height calc(100% - 0.36rem)
                        .el-tab-pane
                            height 100%
                            table
                                width 100%
                                height 100%
                                th
                                    text-align right
                                    font-size 0.14rem
                                    color #333333
                                    font-weight 400
                                    padding .02rem
                                    background #fff
                                    div
                                        width 100%
                                        height 100%
                                        background #F8F8F8
                                td
                                    .el-select,.el-input-number,.el-input
                                        width 1.3rem
                                    .el-input__inner
                                        padding-right 0
                                    .el-input-group__append
                                        padding 0 0.06rem
// 砼车发货-选择车辆弹出换车原因弹出层样式
.changeEject
    height auto
    .el-dialog__body
        overflow hidden
        padding 0.15rem 0.2rem
        h4,
        .el-radio-group
            float left
            font-size 0.16rem
            line-height 0.36rem
        h4
            width 1rem
        .el-radio-group
            width calc(100% - 1rem)
.dialogCallPhone
    position fixed
    left 0
    top 0
    width 100%;
    height 100%;
    background none;
    display flex;
    justify-content center;
    align-items center;
    background rgba(0,0,0,0.7);
    z-index 100;
    .container-box
        width 4rem;
        height 3rem;
        padding 0.07rem;
        background: rgba(255, 255, 255, 0.3);
        .call-content
            width 100%;
            height 100%;
            background #fff;
            padding 0.3rem;
            h2
                font-size 0.2rem;
                color #333333;
                font-weight 500;
                margin-bottom 0.15rem;
            .item
                display flex;
                align-items center;
                margin 0.2rem 0;
                label
                    display flex
                    align-items center;
                    width 2rem;
                    font-size 0.16rem;
                    color #666666;
                    i
                        font-size 0.16rem;
                        margin-right 0.1rem;
                .el-input,/deep/ .el-input
                    flex 1;
            .btns
                margin-top 0.4rem
                display flex;
                justify-content space-around;
                align-items center;
                .btn
                    display flex
                    justify-content center;
                    align-items center;
                    width: 1.4rem;
                    height: 0.4rem;
                    border-radius: 1px;
                    border: 1px solid #1577D2;
                    color #1577D2;
                    font-size 0.16rem;
                    &.btn1
                        background: #1577D2;
                        border-radius: 1px;
                        color #fff;

// 砼车发货-确认信息弹出层样式
.onfirmationInfo
    .el-dialog__body
        padding 0.2rem
    .onfirmationInfoContent
        li
            margin-bottom 0.1rem
            display flex
            align-items center
            h4
                width 0.8rem
                margin-right 0.1rem
                font-size 0.14rem
                line-height 0.16rem
                text-align right
            p
                width calc(100% - 0.9rem)
                border: 1px solid #DCDFE6;
                border-radius 2px
                height 0.36rem
                line-height 0.36rem
                padding 0 0.1rem


.table-bg-shipments
    .el-table
        tr
            >td
                &:first-child
                    background url('../images/nopitch.png') no-repeat !important
                    background-size 100% 100%
            &.current-row
                >td
                    background #fff !important
                    border-top 1px solid #1579FE !important
                    border-bottom 1px solid #1579FE !important
                    &:first-child
                        background url('../images/pitch.png') no-repeat !important
                        background-size 100% 100%
                        border-left 1px solid #1579FE !important
                    &:last-child
                        border-right 1px solid #1579FE !important
</style>
